/* Styles scoped specifically for the ToS container */
.tos-container {
  padding: 40px 20px;
  max-width: 900px;
  margin: 50px auto;
  background: #ffffff; /* Changed to solid white for better readability */
  border: 1px solid #dcdcdc; /* Added a subtle border for structure */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-family: 'Roboto', sans-serif;
  color: #2c2c2c; /* Neutral dark gray for text */
  line-height: 1.8;
  transition: all 0.3s ease;
}

.tos-container:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* Header styling */
.tos-container h1 {
  font-size: 34px;
  color: #1a1a1a; /* Black for bold emphasis */
  text-align: center;
  margin-bottom: 25px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  border-bottom: 2px solid #dcdcdc; /* Subtle underline effect */
  padding-bottom: 10px;
}

.tos-container h2 {
  font-size: 26px;
  color: #2c2c2c; /* Dark gray for headings */
  margin-top: 30px;
  margin-bottom: 15px;
  text-transform: capitalize;
  font-weight: 600;
}

/* Paragraph styling */
.tos-container p {
  font-size: 17px;
  line-height: 1.8;
  color: #333333; /* Neutral dark gray for body text */
  margin-bottom: 15px;
  text-align: justify;
}

/* Uniform bullet point styling */
.tos-container ul {
  margin: 15px 0 20px 40px;
  padding-left: 0;
  list-style-type: disc;
}

.tos-container ul li {
  margin-bottom: 10px;
  font-size: 17px;
  color: #2c2c2c;
}

/* Link styling */
.tos-container a {
  color: #007acc; /* Softer blue for links */
  text-decoration: none;
  transition: color 0.2s ease;
}

.tos-container a:hover {
  color: #005999; /* Darker blue on hover */
  text-decoration: underline;
}

/* Strong text */
.tos-container strong {
  font-weight: 700;
  color: #000000; /* Black for emphasis */
}

/* Blockquote styling */
.tos-container blockquote {
  margin: 20px 0;
  padding: 20px;
  background: #f8f8f8; /* Light gray for contrast */
  border-left: 5px solid #007acc;
  font-style: italic;
  color: #4d4d4d;
}

/* Footer styling */
.tos-container footer {
  text-align: center;
  margin-top: 40px;
}

.tos-container footer a {
  color: #333333; /* Neutral gray for footer links */
  font-size: 16px;
  margin: 0 15px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
}

/* Responsive styling */
@media (max-width: 768px) {
  .tos-container {
      padding: 20px 15px;
  }

  .tos-container h1 {
      font-size: 28px;
  }

  .tos-container h2 {
      font-size: 22px;
  }

  .tos-container p, .tos-container ul li {
      font-size: 15px;
  }

  .tos-container footer a {
      font-size: 14px;
  }
}
