.map-container {
  height: 100vh;
  width: 100%;
  position: relative;
}

.completion-container, .summary-container {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.complete-button, .join-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.complete-button:hover, .join-button:hover {
  background-color: #45a049;
}

.summary-card {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  max-width: 600px;
  margin: auto;
  text-align: left;
}

.fare-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.location {
  margin-top: 10px;
  font-size: 16px;
}

.join-prompt {
  margin-top: 30px;
  padding: 10px;
  background-color: #ffeded;
  border-radius: 5px;
}
