.coming-soon-container {
    /* Full viewport coverage */
    width: 100vw;
    height: 100vh;
    display: flex; 
    justify-content: center; 
    align-items: center;
  
    /* A subtle background gradient */
    background: linear-gradient(to bottom right, #ffffff, #f4f4f4);
    font-family: sans-serif;
  }
  
  .content-wrapper {
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    background: #fff; /* A white box on the subtle background */
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }
  
  .coming-soon-logo {
    width: 120px;
    margin-bottom: 20px;
  }
  
  .headline {
    font-size: 2rem;
    color: #8C1627; /* Using your typical red color */
    margin-bottom: 10px;
  }
  
  .subtext {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 30px;
  }
  
  .home-link {
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;
    color: #8C1627;  /* Corrected this line to use one '#' */
    font-weight: bold;
    border: 2px solid #8C1627;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background 0.3s ease, color 0.3s ease;
  }
  
  .home-link:hover {
    background: #8C1627;
    color: #fff;
  }
  