/* src/pages/DriverNavigationPage.css */

/* =========================================
   Root Variables for Theme Colors
   ========================================= */
   :root {
    --primary-color: #8C1627; /* Deep Burgundy */
    --secondary-color: #D9534F; /* Vibrant Red */
    --background-color: #f8f9fa; /* Light Grey */
    --card-background-color: #ffffff; /* White */
    --text-color: #212529; /* Rich Black */
    --accent-text-color: #ffffff; /* White for buttons */
    --border-color: #e0e0e0; /* Subtle Border */
    --shadow-color: rgba(0, 0, 0, 0.1); /* Subtle Shadow */
    --hover-primary: #a91a2c; /* Darker Burgundy */
    --hover-secondary: #c9302c; /* Darker Red */
    --error-color: #ff4d4f; /* Bright Red */
    --success-color: #28a745; /* Green for success messages */
    --font-family: 'Poppins', sans-serif;
  }
  
  /* =========================================
     Global Styles
     ========================================= */
  body {
    margin: 0;
    padding: 0;
    background-color: var(--background-color);
    font-family: var(--font-family);
    color: var(--text-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  * {
    box-sizing: border-box;
  }
  
  /* =========================================
     Blitz Logo
     ========================================= */
  .blitz-logo {
    display: block;
    margin: 0 auto 20px auto;
    width: 150px;
    height: auto;
  }
  
  /* =========================================
     Main Container
     ========================================= */
  .driver-navigation-container {
    width: 95%;
    max-width: 500px;
    margin: 20px auto;
    padding: 25px;
    background-color: var(--card-background-color);
    border-radius: 20px;
    box-shadow: 0 10px 25px var(--shadow-color);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .driver-navigation-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px var(--shadow-color);
  }
  
  .driver-navigation-container h2 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--primary-color);
    text-align: center;
    margin-bottom: 25px;
  }
  
  /* =========================================
     Main Content Styling
     ========================================= */
  .main-content {
    /* Additional styling can be added here if needed */
  }
  
  /* =========================================
     Pickup and Dropoff Section
     ========================================= */
  .pickup-dropoff-container {
    padding: 25px 30px;
    background-color: var(--card-background-color);
    border-radius: 15px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
    margin-bottom: 30px;
  }
  
  .location-row {
    display: flex;
    align-items: center; /* Center vertically to align icons with text */
    gap: 20px; /* Adjusted gap for better alignment */
  }
  
  .location-icon {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 24px; /* Adjusted width */
    min-width: 24px; /* Adjusted min-width */
    height: 60px; /* Fixed height to accommodate icons and line */
  }
  
  /* Create the vertical connecting line using a pseudo-element */
  .location-icon::after {
    content: '';
    position: absolute;
    top: 14px; /* Position below the circle */
    bottom: 14px; /* Position above the rhombus */
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    background-color: var(--primary-color);
    z-index: 1;
  }
  
  .location-icon .circle,
  .location-icon .rhombus {
    width: 12px; /* Smaller size for better alignment */
    height: 12px; /* Smaller size for better alignment */
    background-color: var(--primary-color);
    border: 2px solid var(--card-background-color);
    position: relative;
    z-index: 2;
  }
  
  .location-icon .circle {
    border-radius: 50%;
    margin-bottom: 2px; /* Space between circle and line */
  }
  
  .location-icon .rhombus {
    transform: rotate(45deg);
    margin-top: 2px; /* Space between line and rhombus */
  }
  
  .location-details {
    flex: 1;
  }
  
  .location-details p {
    margin: 10px 0;
    font-size: 1.1rem;
    color: var(--text-color);
    line-height: 1.6;
  }
  
  .location-details p span {
    font-weight: 600;
    color: var(--primary-color);
  }
  
  /* =========================================
     Ride Details Section
     ========================================= */
  .ride-details {
    background-color: var(--card-background-color);
    padding: 25px 30px;
    border-radius: 15px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
    margin-bottom: 30px;
  }
  
  .ride-details p {
    margin: 10px 0;
    font-size: 1.1rem;
    color: var(--text-color);
  }
  
  .ride-details p span {
    font-weight: 600;
    color: var(--primary-color);
  }
  
  /* =========================================
     Buttons
     ========================================= */
  .button-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .navigate-button,
  .complete-ride-button {
    padding: 18px;
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--accent-text-color);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .navigate-button {
    background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  }
  
  .complete-ride-button {
    background: var(--secondary-color);
  }
  
  .navigate-button:hover {
    background: linear-gradient(45deg, var(--hover-primary), var(--hover-secondary));
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .complete-ride-button:hover {
    background: #bf3e3a; /* Darker shade of secondary color */
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .navigate-button:disabled,
  .complete-ride-button:disabled {
    background-color: var(--border-color);
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
  
  /* =========================================
     Error and Loading Messages
     ========================================= */
  .error-message,
  .loading-message {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .error-message {
    color: var(--error-color);
  }
  
  .loading-message {
    color: var(--text-color);
  }
  
  /* =========================================
     Responsive Design for Mobile
     ========================================= */
  @media (max-width: 768px) {
    .driver-navigation-container {
      padding: 20px;
    }
  
    .pickup-dropoff-container,
    .ride-details {
      padding: 20px 25px;
      margin-bottom: 25px;
    }
  
    .navigate-button,
    .complete-ride-button {
      padding: 15px;
      font-size: 1rem;
    }
  
    .location-row {
      gap: 15px; /* Reduced gap for smaller screens */
    }
  
    .location-icon {
      width: 20px; /* Adjusted width for smaller screens */
      min-width: 20px; /* Adjusted min-width for smaller screens */
      height: 50px; /* Adjusted height for smaller screens */
    }
  
    .location-icon::after {
      top: 12px; /* Adjusted for smaller icons */
      bottom: 12px; /* Adjusted for smaller icons */
    }
  
    .location-icon .circle,
    .location-icon .rhombus {
      width: 10px; /* Smaller size for mobile */
      height: 10px; /* Smaller size for mobile */
    }
  
    .location-details p {
      font-size: 1rem;
    }
  
    .driver-navigation-container h2 {
      font-size: 1.5rem;
    }
  }
  