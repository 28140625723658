.tc-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px; /* Increased padding for more space */
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.8; /* Improved line height for readability */
}

h1 {
    text-align: center;
    margin-bottom: 25px;
    color: #8c1627;
    font-size: 2rem;
}

h2 {
    margin-top: 35px;
    color: #8c1627;
    font-size: 1.5rem;
}

p {
    line-height: 1.8; /* Increased line height for better spacing */
    margin-bottom: 20px; /* Increased margin for separation */
    font-size: 1rem;
}

ul {
    margin-bottom: 25px;
    padding-left: 25px; /* Increased padding for better spacing */
}

li {
    margin-bottom: 15px; /* Increased spacing between list items */
    font-size: 1rem;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
    .tc-container {
        padding: 20px; /* Adjusted padding for tablets */
    }

    h1 {
        font-size: 1.75rem;
    }

    h2 {
        font-size: 1.3rem;
        margin-top: 25px;
    }

    p, li {
        font-size: 0.95rem;
        line-height: 1.6; /* Slightly increased for readability */
    }

    ul {
        padding-left: 20px;
    }
}

/* Extra small devices (max-width: 480px) */
@media (max-width: 480px) {
    .tc-container {
        padding: 15px; /* Reduced padding for small screens */
    }

    h1 {
        font-size: 1.5rem;
        margin-bottom: 20px;
    }

    h2 {
        font-size: 1.2rem;
        margin-top: 20px;
    }

    p, li {
        font-size: 0.9rem;
        line-height: 1.5; /* Adjusted for small screens */
    }

    ul {
        padding-left: 15px;
    }
}
