/* Overall container for the onboarding process */
.onboarding-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  transition: box-shadow 0.3s ease;
}

.onboarding-container:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

/* Steps styling */
.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  animation: fadeIn 0.6s ease-in-out;
}

.step-container h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: 600;
}

.step-container p {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 25px;
}

/* Input styling */
.step-container input[type="text"],
.step-container input[type="date"],
.step-container input[type="file"] {
  width: 100%;
  padding: 12px 15px;
  margin: 12px 0;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 1rem;
  background-color: #f0f0f0;
  transition: border-color 0.3s;
}

.step-container input[type="text"]:focus,
.step-container input[type="date"]:focus,
.step-container input[type="file"]:focus {
  outline: none;
  border-color: #8C1627;
  background-color: #fff;
}

/* Button container for navigation */
.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

.button-container button {
  background-color: #8C1627;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.button-container button:hover {
  background-color: #75111f;
  transform: translateY(-2px);
}

.button-container button:active {
  transform: scale(0.98);
}

.button-container button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  pointer-events: none;
}

/* Consent checkbox */
.consent-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
}

.consent-button input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.3);
  cursor: pointer;
}

.consent-text {
  font-size: 1rem;
  color: #555;
}

/* Summary section */
.summary {
  text-align: left;
  width: 100%;
  background-color: #f5f5f5;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.summary p {
  font-size: 1.1rem;
  color: #333;
  margin: 8px 0;
}

.summary strong {
  color: #8C1627;
}

/* Submit button */
.submit-button {
  background-color: #8C1627;
  color: #fff;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-align: center;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #75111f;
  transform: translateY(-2px);
}

.submit-button:active {
  transform: scale(0.98);
}

/* Keyframe animation for smooth transitions */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
