.register-container {
  display: flex;                /* Container uses flex for centering */
  flex-direction: column;       /* Stacks elements vertically */
  align-items: center;          /* Center align items horizontally */
  justify-content: center;      /* Center align items vertically */
  height: 100vh;                /* Full viewport height */
  padding: 20px;                /* Padding around the container */
  background-color: #FFFFFF;    /* White background */
}

.logo {
  width: 150px;                 /* Logo width */
  margin-bottom: 30px;          /* Space below the logo */
}

h1 {
  font-size: 28px;              /* Larger text for the heading */
  font-weight: bold;            /* Bold heading */
  color: #333333;               /* Dark gray text color */
  margin-bottom: 30px;          /* Space below heading */
}

.input-field {
  width: 100%;                  /* Full width */
  max-width: 400px;             /* Max width for desktop */
  height: 50px;                 /* Height of the input box */
  margin-bottom: 20px;          /* Space below input */
  padding: 0 20px;              /* Horizontal padding inside input */
  border-radius: 0;             /* REMOVED rounded corners for a square look */
  border: 1px solid #cccccc;    /* Light gray border */
  background-color: #f2f2f2;     /* Light gray background */
  font-size: 16px;              /* Font size inside input */
  color: #333333;               /* Dark gray text */
}

.register-button {
  width: 100%;                  /* Full width */
  max-width: 400px;             /* Max width for desktop */
  height: 50px;                 /* Height of the button */
  border-radius: 0;             /* REMOVED rounded corners for a square look */
  background-color: #8C1627;     /* Brand color background */
  color: #FFFFFF;               /* White text */
  font-weight: bold;            /* Bold text */
  font-size: 18px;              /* Larger font size */
  cursor: pointer;              /* Pointer cursor on hover */
  border: none;                 /* No border */
  margin-bottom: 20px;          /* Space below button */
  transition: background-color 0.3s; /* Smooth hover transition */
}

.register-button.disabled {
  background-color: #a7a7a7;     /* Gray background when disabled */
  cursor: not-allowed;           /* Not-allowed cursor when disabled */
}

.footer-text {
  font-size: 16px;              /* Font size of footer text */
  color: #666666;               /* Gray text color */
}

.login-link {
  color: #8C1627;               /* Brand color link */
  font-weight: bold;            /* Bold link */
  cursor: pointer;              /* Pointer cursor on hover */
}

/* NEW: Password container and toggle button for revealing password */
.password-container {
  position: relative;           /* Position relative to place icon */
  width: 100%;                  /* Full width */
  max-width: 400px;             /* Max width matches input */
  margin-bottom: 20px;          /* Space below container */
}

.toggle-password-button {
  position: absolute;           /* Position absolutely within container */
  right: 20px;                  /* Place near the right edge of input */
  top: 50%;                     /* Vertically center the icon */
  transform: translateY(-50%);  /* Adjust for perfect vertical centering */
  background: none;             /* No background */
  border: none;                 /* No border */
  cursor: pointer;              /* Pointer cursor on hover */
  padding: 0;                   /* No padding */
}
