/* InstaBlitzDashboard.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Root Styles */
.instablitz-dashboard {
  padding: 30px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Dashboard Header */
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dashboard-header h2 {
  font-size: 32px;
  margin: 0;
  color: #2D3748;
  font-weight: 700;
}

.header-buttons {
  display: flex;
  gap: 15px;
}

.logout-btn,
.back-to-admin-btn {
  background-color: #3182CE;
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.logout-btn:hover,
.back-to-admin-btn:hover {
  background-color: #2B6CB0;
  transform: translateY(-2px);
}

.logout-btn:active,
.back-to-admin-btn:active {
  background-color: #2C5282;
  transform: translateY(0);
}

/* Tabs */
.tabs {
  display: flex;
  border-bottom: 2px solid #E2E8F0;
  margin-bottom: 20px;
}

.tabs button {
  background: none;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: #4A5568;
  border-bottom: 2px solid transparent;
  transition: color 0.3s ease, border-bottom-color 0.3s ease;
}

.tabs button:hover {
  color: #2B6CB0;
}

.tabs button.active {
  color: #2B6CB0;
  border-bottom-color: #2B6CB0;
}

/* Payment Counters */
.payment-counters {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

.payment-counters .counter {
  background-color: #EDF2F7;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  color: #2D3748;
}

/* Search Container */
.search-container {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.search-input {
  padding: 12px 20px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #CBD5E0;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.search-input:focus {
  border-color: #3182CE;
  box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.2);
}

/* Loading and Error States */
.loading,
.error,
.no-drivers,
.no-payments {
  text-align: center;
  font-size: 18px;
  color: #555555;
  margin-top: 20px;
}

.error {
  color: #E53E3E;
}

/* Table Container */
.table-container {
  overflow-x: auto;
  margin-top: 20px;
}

/* Table Styling */
table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;
}

table:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

table th,
table td {
  padding: 15px 18px;
  text-align: left;
  border-bottom: 1px solid #ECECEC;
  font-size: 15px;
  color: #4A5568;
}

table th {
  background-color: #F7FAFC;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

table tr:nth-child(even) {
  background-color: #F9FAFB;
}

table tr:hover {
  background-color: #EDF2F7;
  cursor: default;
}

/* Status Badges */
.status-badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}

.status-badge.pending {
  background-color: #ED8936; /* Orange */
}

.status-badge.paid {
  background-color: #38A169; /* Green */
}

.status-badge.rejected {
  background-color: #E53E3E; /* Red */
}

.status-badge.to-review {
  background-color: #3182CE; /* Blue */
}

/* License Image Styling */
.license-img {
  width: 80px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #CBD5E0;
}

/* Action Buttons Styling */
.delete-btn,
.add-payment-btn {
  padding: 8px 14px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  color: #ffffff;
}

.delete-btn {
  background-color: #E53E3E;
}

.delete-btn:hover {
  background-color: #C53030;
  transform: translateY(-2px);
}

.delete-btn:active {
  background-color: #9B2C2C;
  transform: translateY(0);
}

.add-payment-btn {
  background-color: #38A169;
  margin-top: 10px;
}

.add-payment-btn:hover {
  background-color: #2F855A;
  transform: translateY(-2px);
}

.add-payment-btn:active {
  background-color: #276749;
  transform: translateY(0);
}

/* Payment Form Styling */
.payment-form {
  background-color: #F7FAFC;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.payment-form h3 {
  margin-top: 0;
  color: #2D3748;
}

.payment-form .form-group {
  margin-bottom: 15px;
}

.payment-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #4A5568;
}

.payment-form input,
.payment-form select {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #CBD5E0;
  border-radius: 6px;
  font-size: 15px;
  outline: none;
  transition: border-color 0.3s ease;
}

.payment-form input:focus,
.payment-form select:focus {
  border-color: #3182CE;
}

.payment-form button {
  width: 100%;
}

/* Status Actions Styling */
.status-actions {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* Date Picker Container */
.date-picker-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.mark-paid-btn {
  padding: 6px 12px;
  background-color: #38A169;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.mark-paid-btn:hover {
  background-color: #2F855A;
  transform: translateY(-2px);
}

.mark-paid-btn:active {
  background-color: #276749;
  transform: translateY(0);
}

/* Responsive Styling */
@media (max-width: 1024px) {
  .instablitz-dashboard {
    padding: 20px;
  }

  .dashboard-header h2 {
    font-size: 28px;
  }

  .search-input {
    max-width: 350px;
  }

  table th,
  table td {
    padding: 12px 16px;
    font-size: 14px;
  }

  .license-img {
    width: 70px;
    height: 50px;
  }

  .delete-btn,
  .add-payment-btn,
  .mark-paid-btn {
    padding: 6px 12px;
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .instablitz-dashboard {
    padding: 15px;
  }

  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .header-buttons {
    width: 100%;
    justify-content: flex-start;
  }

  .search-container {
    justify-content: flex-start;
  }

  .search-input {
    width: 100%;
    max-width: none;
  }

  .license-img {
    width: 60px;
    height: 45px;
  }

  .delete-btn,
  .add-payment-btn,
  .mark-paid-btn {
    width: 100%;
    text-align: center;
  }

  .status-actions {
    flex-direction: column;
  }

  .date-picker-container {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .instablitz-dashboard {
    padding: 10px;
  }

  .dashboard-header h2 {
    font-size: 24px;
  }

  .header-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .logout-btn,
  .back-to-admin-btn {
    width: 100%;
    margin: 5px 0;
  }

  .search-input {
    padding: 10px 14px;
    font-size: 14px;
  }

  table th,
  table td {
    padding: 8px 10px;
    font-size: 12px;
  }

  .license-img {
    width: 50px;
    height: 40px;
  }

  .delete-btn,
  .add-payment-btn,
  .mark-paid-btn {
    padding: 8px 14px;
    font-size: 12px;
  }

  .payment-form {
    padding: 15px;
  }

  .payment-form h3 {
    font-size: 20px;
  }
}
