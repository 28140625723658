/* Container Styling */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background-color: #FFFFFF;
}

/* Logo Styling */
.login-container .logo {
  width: 150px;
  margin-bottom: 30px;
}

/* Heading Styling */
.login-container h1 {
  font-size: 28px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 30px;
}

/* Email Input Field Styling */
.login-container .input-field {
  width: 100%;
  max-width: 400px;
  height: 50px;
  margin-bottom: 20px;
  padding: 0 20px;
  border-radius: 0; /* Rectangular edges */
  border: 1px solid #cccccc;
  background-color: #f2f2f2;
  font-size: 16px;
  color: #333333;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

/* Password Container to Position Toggle Button */
.login-container .password-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

/* Password Input Field Styling */
.login-container .password-input {
  width: 100%;
  height: 50px;
  padding: 0 40px 0 20px; /* Increased right padding to accommodate toggle button */
  border-radius: 0; /* Rectangular edges */
  border: 1px solid #cccccc;
  background-color: #f2f2f2;
  font-size: 16px;
  color: #333333;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

/* Toggle Password Button Styling */
.login-container .toggle-password-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
}

/* Toggle Password Button Hover Effect */
.login-container .toggle-password-button:hover {
  opacity: 0.7;
}

/* Login Button Styling */
.login-container .login-button {
  width: 100%;
  max-width: 300px; /* Shortened from 400px */
  height: 40px; /* Shortened from 50px */
  border-radius: 0; /* Rectangular edges */
  background-color: #8C1627;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  border: none;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

/* Login Button Hover Effect */
.login-container .login-button:hover:not(.disabled) {
  background-color: #6B0E1C;
}

/* Disabled Login Button Styling */
.login-container .login-button.disabled {
  background-color: #a7a7a7;
  cursor: not-allowed;
}

/* Footer Text Styling */
.login-container .footer-text {
  font-size: 16px;
  color: #666666;
}

/* Register Link Styling */
.login-container .register-link {
  color: #8C1627;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

/* Register Link Hover Effect */
.login-container .register-link:hover {
  color: #6B0E1C;
}

/* Focus Styles for Accessibility */
.login-container .input-field:focus, 
.login-container .password-input:focus {
  border-color: #8C1627;
  box-shadow: 0 0 5px rgba(140, 22, 39, 0.5);
  outline: none;
}

.login-container .toggle-password-button:focus {
  outline: 2px solid #8C1627;
  outline-offset: 2px;
}
