/* General Styling */
.one-pager {
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f7f7f7;
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensure all elements use border-box sizing */
}

/* Hero Section */
.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 90vh;
  background-color: #8C1627;
  text-align: left;
  padding: 20px;
  color: white;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
}

.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 150px;
}

.main-nav {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 15px;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s;
}

.nav-link:hover {
  border-bottom: 2px solid white;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px 0;
  transition: 0.4s;
}

/* Navigation Menu */
.main-nav {
  display: flex;
}

.main-nav.active {
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  top: 70px;
  right: 20px;
  background-color: #8C1627;
  padding: 15px;
  border-radius: 8px;
}

.main-nav.active .nav-link {
  padding: 10px 0;
}

/* Content */
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
}

.text-content {
  max-width: 45%;
  padding-right: 20px;
  margin-bottom: 20px; /* Space between text and image on smaller screens */
}

.text-content h1 {
  font-size: 3.5rem;
  margin-bottom: 20px;
  color: white;
}

.text-content p {
  font-size: 1.4rem;
  margin-bottom: 30px;
}

.main-image {
  max-width: 30%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  margin-left: 20px;
}

.cta-buttons {
  display: flex;
  gap: 20px;
  flex-wrap: wrap; /* Allows buttons to wrap on smaller screens */
}

.cta-buttons .btn {
  background-color: white;
  color: #8C1627;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 25px;
  border: 2px solid #8C1627;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap; /* Prevent button text from wrapping */
  box-sizing: border-box; /* Ensure button size accounts for padding and borders */
  max-width: 100%; /* Ensure button does not overflow on small screens */
}

.cta-buttons .btn:hover {
  background-color: #8C1627;
  color: white;
}

/* About Section */
.about-section {
  padding: 60px 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  margin: 40px 0;
  text-align: center;
}

.about-content {
  max-width: 80%;
  margin: 0 auto;
}

.about-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #8C1627;
}

.about-content p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
}

.highlight {
  color: #8C1627;
  font-weight: bold;
}

/* Earnings Comparison Section */
.earnings-comparison-section {
  padding: 60px 20px;
  background: linear-gradient(135deg, #f7f7f7, #e2e2e2);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  margin: 40px 0;
  text-align: center;
}

.comparison-content {
  max-width: 80%;
  margin: 0 auto;
}

.comparison-inputs {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-group label {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #555;
}

.input-group input {
  width: 120px;
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  text-align: center;
}

.calculate-btn {
  background-color: #8C1627;
  color: white;
  padding: 15px 30px;
  border-radius: 25px;
  border: none;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s, transform 0.3s;
}

.calculate-btn:hover {
  background-color: #6b1120;
  transform: translateY(-2px);
}

.comparison-results {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  gap: 20px;
}

.result-group {
  flex: 1;
  padding: 20px;
  background: linear-gradient(135deg, #ffffff, #f9f9f9);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.result-group.highlight {
  background: linear-gradient(135deg, #8C1627, #a83232);
  color: white;
}

.result-group.highlight .result-box p {
  color: white; /* Ensures all text inside the Blitz box is white */
}

.result-group.highlight .result-box span {
  color: white; /* Ensures the highlighted text inside the Blitz box is white */
}

.result-group:hover {
  transform: translateY(-10px);
}

.result-box {
  border-radius: 10px;
  padding: 15px;
  background-color: transparent;
  margin-top: 10px;
}

.result-box p {
  font-size: 1.5rem;
  color: inherit;
}

.result-box span {
  font-weight: bold;
  color: #333;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  margin-top: 10px;
}

.progress {
  height: 100%;
  background-color: #8C1627;
  border-radius: 5px;
  transition: width 0.3s;
}

/* How It Works Section */
.how-it-works-section {
  padding: 60px 20px;
  text-align: center;
  background-color: #f1f1f1;
  margin: 40px 0;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.how-it-works-section h2 {
  font-size: 2.5rem;
  color: #8C1627;
  margin-bottom: 40px;
}

.steps {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  flex-wrap: wrap;
}

.step {
  width: 30%;
  min-width: 250px;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.step:hover {
  transform: translateY(-10px);
}

.step h3 {
  font-size: 1.8rem;
  color: #8C1627;
  margin-bottom: 10px;
}

.step p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

/* Drive Section */
.cta-driver-section {
  padding: 60px 20px;
  text-align: center;
  background-color: #ffffff;
  margin: 40px 0;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.driver-content {
  max-width: 70%;
  margin: 0 auto;
}

.benefits-list {
  text-align: left;
  margin: 20px 0 40px;
  list-style: none;
  padding-left: 0;
}

.benefits-list li {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #555;
}

.checkmark {
  color: #8C1627;
  font-weight: bold;
  margin-right: 10px;
}

.primary-btn {
  display: inline-block;
  padding: 15px 30px;
  background-color: #8C1627;
  color: white;
  font-weight: bold;
  border-radius: 25px;
  border: 2px solid #8C1627;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap; /* Prevent button text from wrapping */
  max-width: 100%; /* Ensure button does not overflow on small screens */
  box-sizing: border-box; /* Ensure button size accounts for padding and borders */
}

.primary-btn:hover {
  background-color: white;
  color: #8C1627;
}

/* Footer */
footer {
  background-color: #8C1627;
  color: white;
  padding: 20px;
  text-align: center;
}

.footer-links a {
  margin: 0 15px;
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.footer-links a:hover {
  text-decoration: underline;
}

/* Responsive Design for Mobile & Tablet */
@media (max-width: 1024px) {
  .text-content {
    max-width: 100%;
    padding-right: 0;
  }

  .main-image {
    margin-left: 0;
    max-width: 100%;
    margin-top: 20px; /* Adds space between text and image on smaller screens */
  }

  .about-content, .comparison-content, .driver-content {
    max-width: 90%;
  }

  .cta-buttons {
    justify-content: center; /* Center buttons on smaller screens */
  }

  .comparison-results {
    flex-direction: column;
    align-items: center; /* Center the result boxes on smaller screens */
  }

  .result-group {
    width: 100%;
    margin-bottom: 20px; /* Adds spacing between result groups on smaller screens */
  }

  .step {
    width: 100%; /* Full width on smaller screens */
  }

  .primary-btn {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    height: auto; /* Adjusts height for smaller screens */
    text-align: center; /* Centers text on smaller screens */
    padding: 40px 20px;
  }

  .logo {
    position: static;
    margin-bottom: 20px;
  }

  .main-nav {
    display: none;
  }

  .main-nav.active {
    display: flex;
  }

  .hamburger {
    display: flex;
  }

  .text-content {
    text-align: center; /* Centers text on smaller screens */
    padding-right: 0;
  }

  .text-content h1 {
    font-size: 2.5rem; /* Adjusted for smaller screens */
  }

  .text-content p {
    font-size: 1.2rem; /* Adjusted for smaller screens */
  }

  .about-section, .how-it-works-section, .earnings-comparison-section, .cta-driver-section {
    padding: 30px 20px;
  }

  .comparison-inputs {
    flex-direction: column;
    align-items: center; /* Center the inputs on smaller screens */
  }

  .input-group input {
    width: 100%; /* Full width on smaller screens */
  }

  .calculate-btn {
    width: 100%;
  }

  .comparison-results {
    flex-direction: column;
    align-items: center; /* Center the result boxes on smaller screens */
  }

  .result-group {
    width: 100%;
    margin-bottom: 20px; /* Adds spacing between result groups on smaller screens */
  }

  .step {
    width: 100%; /* Full width on smaller screens */
  }

  .primary-btn {
    width: 100%;
  }
}

/* Additional Responsive Adjustments */
@media (max-width: 480px) {
  .hero-section {
    padding: 20px 10px;
  }

  .text-content h1 {
    font-size: 2rem; /* Further adjustment for very small screens */
  }

  .text-content p {
    font-size: 1rem;
  }

  .step h3 {
    font-size: 1.5rem; /* Adjusted for smaller screens */
  }

  .step p {
    font-size: 0.9rem; /* Adjusted for smaller screens */
  }

  .about-content h2,
  .how-it-works-section h2 {
    font-size: 2rem; /* Smaller font size for smaller screens */
  }
}
/* InstaBlitz Section Styling */
.instablitz-section {
  padding: 50px;
  background-color: #f5f5f5;
  text-align: center;
}

.instablitz-steps {
  display: flex;
  justify-content: center;
  align-items: stretch; /* Ensure steps stretch to the same height */
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap; /* Allows steps to wrap on smaller screens */
}

.step {
  flex: 1 1 250px; /* Ensures each step has equal width and grows uniformly */
  margin: 10px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center-aligns content inside each step */
}

.step:hover {
  transform: translateY(-5px); /* Adds a hover effect */
}

.step .icon-wrapper {
  width: 60px;
  height: 60px;
  margin-bottom: 15px; /* Space between the icon and the heading */
  display: flex;
  justify-content: center;
  align-items: center;
}

.step .icon {
  font-size: 50px;
  color: #8c1627;
}

.step h3 {
  font-size: 1.5rem;
  margin: 10px 0;
}

.step .content {
  flex-grow: 1; /* Makes the content area take up remaining space */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.step p {
  font-size: 1rem;
  line-height: 1.6;
  margin: 0;
  flex-grow: 1;
}

.instablitz-cta {
  margin-top: 40px;
}

.cta-btn {
  background-color: #8c1627;
  color: white;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
}

/* Mobile Adjustments for InstaBlitz Section */
@media (max-width: 768px) {
  .instablitz-steps {
    flex-direction: column; /* Stack steps vertically on mobile */
    align-items: center;
  }

  .step {
    width: 90%; /* Full width on smaller screens */
    margin-bottom: 20px;
  }

  .step .icon-wrapper {
    width: 50px;
    height: 50px;
  }

  .step .icon {
    font-size: 40px; /* Adjust icon size for smaller screens */
  }

  .step h3 {
    font-size: 1.3rem; /* Adjust heading size on mobile */
  }

  .step p {
    font-size: 0.9rem; /* Adjust text size for mobile */
  }
}
.countdown-banner {
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2); /* Soft, neutral gradient */
  color: #333; /* Dark text for contrast */
  padding: 15px; /* Reduced padding for smaller screens */
  text-align: center;
  font-size: 1.3rem; /* Slightly smaller default font size */
  font-weight: 600; /* Maintain emphasis */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slightly deeper shadow for elegance */
  border-radius: 8px; /* Rounded corners for a softer look */
  margin: 15px auto; /* Adjusted margin for mobile */
  max-width: 90%; /* Narrower on mobile for better spacing */
  position: relative; /* Ensure structure stays intact */
  z-index: 10; /* Keep banner above other elements */
  transition: transform 0.3s ease-in-out;
}

.countdown-banner:hover {
  transform: translateY(-5px); /* Hover effect */
}

.countdown-banner span {
  color: #ff4b5c; /* Vibrant red for countdown */
  font-weight: 600;
  margin-left: 8px; /* Adjusted for mobile spacing */
  font-size: 1.4rem; /* Smaller countdown font on mobile */
}

.countdown-banner p {
  margin: 0;
  line-height: 1.4; /* Slightly tighter line height on mobile */
}

.countdown-banner p::before {
  content: '🚀 ';
  font-size: 1.5rem; /* Slightly smaller rocket icon for mobile */
  vertical-align: middle;
}

/* Larger Screens */
@media (min-width: 768px) {
  .countdown-banner {
    font-size: 1.6rem;
    padding: 25px;
    max-width: 85%; /* Slightly wider for larger screens */
  }

  .countdown-banner span {
    font-size: 1.7rem; /* Larger countdown on bigger screens */
    margin-left: 10px;
  }

  .countdown-banner p::before {
    font-size: 1.8rem; /* Larger icon on bigger screens */
  }
}

/* Small Mobile Screens (e.g., 320px wide) */
@media (max-width: 400px) {
  .countdown-banner {
    padding: 12px; /* Tighter padding on very small screens */
    font-size: 1.2rem; /* Further reduced font size */
  }

  .countdown-banner span {
    font-size: 1.3rem; /* Smaller countdown text */
  }

  .countdown-banner p::before {
    font-size: 1.3rem; /* Smaller rocket icon */
  }
}

/* General Button Style */
.btn {
  background-color: #8C1627; /* New main button color */
  color: #fff; /* White text for contrast */
  padding: 12px 25px; /* Balanced padding */
  border: none; /* No border */
  border-radius: 30px; /* Smooth rounded edges */
  text-transform: uppercase; /* Uppercase for emphasis */
  font-weight: 600; /* Bold font for prominence */
  font-size: 1.1rem; /* Slightly larger font for readability */
  text-align: center;
  text-decoration: none; /* Remove underline */
  display: inline-block;
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
}

.btn:hover {
  background-color: #721221; /* Darker shade of the button color on hover */
  transform: translateY(-2px); /* Slight hover animation */
}

.btn:active {
  transform: translateY(0); /* Reset transform on click */
}

.btn:focus {
  outline: none; /* No outline on focus */
  box-shadow: 0 0 0 4px rgba(140, 22, 39, 0.4); /* Subtle focus ring for accessibility */
}

/* CTA Button in How It Works Section */
.cta-btn {
  background-color: #8C1627; /* Consistent main button color */
}

.cta-btn:hover {
  background-color: #721221; /* Darken the button color on hover */
}

.cta-btn:active {
  transform: translateY(0); /* No transform on click */
}

.cta-btn:focus {
  outline: none; /* Remove focus outline */
  box-shadow: 0 0 0 4px rgba(140, 22, 39, 0.4); /* Consistent focus ring */
}
/* Waitlist Modal Overlay */
.waitlist-overlay {
  position: fixed;                 /* Keep the overlay fixed */
  top: 0;                          /* Align at top */
  left: 0;                         /* Align at left */
  width: 100%;                     /* Full width */
  height: 100%;                    /* Full height */
  background: rgba(0,0,0,0.6);     /* Semi-transparent black overlay */
  display: flex;                   /* Use flex for centering */
  justify-content: center;         /* Center horizontally */
  align-items: center;             /* Center vertically */
  z-index: 9999;                   /* High z-index to overlay everything */
}

/* Waitlist Modal Container */
.waitlist-modal {
  background: #fff;                /* White background */
  border-radius: 12px;             /* Rounded corners */
  max-width: 400px;                /* Max width of the modal */
  width: 90%;                      /* Responsive width */
  padding: 30px;                   /* Inner padding */
  box-shadow: 0 5px 30px rgba(0,0,0,0.2); /* Drop shadow */
  text-align: center;              /* Center text */
  position: relative;              /* Position relative for internal elements */
  max-height: 90%;                 /* Limit max height on small screens */
  overflow-y: auto;                /* Enable vertical scrolling if content overflows */
}

/* Blitz Logo at the top of the waitlist modal */
.waitlist-logo {
  width: 150px;                    /* Logo width */
  margin: 0 auto 20px;             /* Center and space below */
  display: block;                  /* Block for easy centering */
}

/* Headline and Subtext above the selection buttons/form */
.waitlist-headline {
  font-size: 1.8rem;               /* Larger headline text */
  color: #8C1627;                  /* Brand color */
  font-weight: bold;               /* Bold text */
  margin-bottom: 10px;             /* Spacing below */
}

.waitlist-subtext {
  font-size: 1rem;                 /* Normal font size */
  color: #555;                     /* Dark gray text */
  margin-bottom: 30px;             /* Spacing below */
}

/* Waitlist Selection */
.waitlist-selection h3 {
  font-size: 1.5rem;               /* Heading font size */
  color: #333;                     /* Dark text */
  margin-bottom: 15px;             /* Spacing below heading */
}

.waitlist-selection p {
  font-size: 1rem;                 /* Normal text size */
  color: #555;                     /* Dark gray text */
  margin-bottom: 20px;             /* Spacing below paragraph */
}

.selection-buttons {
  display: flex;                   /* Flex for side-by-side buttons */
  justify-content: space-between;  /* Space between them */
  gap: 15px;                       /* Gap between buttons */
  margin-bottom: 20px;             /* Spacing below buttons */
}

.selection-buttons .btn {
  flex: 1;                         /* Each button takes equal width */
  padding: 15px;                   /* Button padding */
  font-size: 1rem;                 /* Font size */
  background: #8C1627;             /* Brand background */
  color: #fff;                     /* White text */
  border-radius: 8px;              /* Rounded corners */
  font-weight: bold;               /* Bold font */
  border: none;                    /* No border */
  cursor: pointer;                 /* Pointer on hover */
  transition: background 0.3s;     /* Smooth hover transition */
}
.selection-buttons .btn:hover {
  background: #721221;             /* Darker background on hover */
}

/* NEW: Already Joined Link */
.already-joined-link {
  font-size: 0.9rem;               /* Slightly smaller text */
  color: #8C1627;                  /* Brand color */
  text-decoration: underline;      /* Underline to indicate link */
  cursor: pointer;                 /* Pointer on hover */
  margin-top: 10px;                /* Spacing above link */
}

/* Waitlist Form */
.waitlist-form h3 {
  font-size: 1.5rem;               /* Heading font size */
  color: #333;                     /* Dark text */
  margin-bottom: 20px;             /* Spacing below heading */
}

.waitlist-form .form-group {
  text-align: left;                /* Left-align labels & inputs */
  margin-bottom: 15px;             /* Spacing between fields */
}

.waitlist-form .form-group label {
  font-size: 0.9rem;               /* Label font size */
  color: #333;                     /* Dark text */
  margin-bottom: 5px;              /* Space below label */
  display: block;                  /* Block to place input below */
}

.waitlist-form .form-group input {
  width: 100%;                     /* Full width input */
  padding: 10px;                   /* Padding inside input */
  font-size: 1rem;                 /* Input font size */
  border: 1px solid #ccc;          /* Light gray border */
  border-radius: 6px;              /* Rounded corners */
  transition: border-color 0.3s;   /* Smooth focus transition */
}

.waitlist-form .form-group input:focus {
  border-color: #8C1627;           /* Brand color on focus */
  outline: none;                   /* Remove outline */
}

.submit-btn {
  width: 100%;                     /* Full width submit button */
  padding: 12px;                   /* Padding inside button */
  font-size: 1rem;                 /* Font size */
  border: none;                    /* No border */
  border-radius: 8px;              /* Rounded corners */
  background: #8C1627;             /* Brand background */
  color: #fff;                     /* White text */
  font-weight: bold;               /* Bold font */
  text-transform: uppercase;       /* Uppercase text */
  margin-top: 20px;                /* Spacing above button */
  cursor: pointer;                 /* Pointer on hover */
  transition: background 0.3s;     /* Smooth hover transition */
}
.submit-btn:hover {
  background: #721221;             /* Darker background on hover */
}

/* Thank You Message */
.thank-you-message {
  text-align: center;              /* Center text */
}

.thank-you-message .check-icon {
  font-size: 3rem;                 /* Large icon */
  color: green;                    /* Green check icon */
  margin-bottom: 20px;             /* Spacing below icon */
}

.thank-you-message h3 {
  font-size: 1.5rem;               /* Heading size */
  color: #333;                     /* Dark text */
  margin-bottom: 10px;             /* Spacing below heading */
}

.thank-you-message p {
  font-size: 1rem;                 /* Normal text */
  color: #555;                     /* Dark gray text */
  margin-bottom: 30px;             /* Spacing below paragraph */
}

.close-btn {
  background: #8C1627;             /* Brand background */
  color: #fff;                     /* White text */
  border: none;                    /* No border */
  font-size: 1rem;                 /* Font size */
  padding: 10px 20px;              /* Padding inside button */
  border-radius: 8px;              /* Rounded corners */
  cursor: pointer;                 /* Pointer on hover */
  font-weight: bold;               /* Bold font */
  transition: background 0.3s;     /* Smooth hover transition */
}
.close-btn:hover {
  background: #721221;             /* Darker background on hover */
}

/* NEW: Already Joined Form */
.already-joined-form h3 {
  font-size: 1.5rem;               /* Heading size */
  color: #333;                     /* Dark text */
  margin-bottom: 20px;             /* Spacing below heading */
}

.already-joined-form .form-group {
  text-align: left;                /* Left-align labels & inputs */
  margin-bottom: 15px;             /* Spacing between fields */
}

.already-joined-form .form-group label {
  font-size: 0.9rem;               /* Label font size */
  color: #333;                     /* Dark text */
  margin-bottom: 5px;              /* Space below label */
  display: block;                  /* Block to place input below */
}

.already-joined-form .form-group input {
  width: 100%;                     /* Full width input */
  padding: 10px;                   /* Padding inside input */
  font-size: 1rem;                 /* Input font size */
  border: 1px solid #ccc;          /* Light gray border */
  border-radius: 6px;              /* Rounded corners */
  transition: border-color 0.3s;   /* Smooth focus transition */
}

.already-joined-form .form-group input:focus {
  border-color: #8C1627;           /* Brand color on focus */
  outline: none;                   /* Remove outline */
}

.already-joined-error {
  color: red;                      /* Red text for error */
  font-size: 0.9rem;               /* Small error text */
  margin-bottom: 10px;             /* Spacing below error message */
}

.return-to-selection {
  font-size: 0.9rem;               /* Slightly smaller text */
  color: #8C1627;                  /* Brand color */
  text-decoration: underline;      /* Underline link */
  cursor: pointer;                 /* Pointer on hover */
  margin-top: 15px;                /* Spacing above link */
}

/* OPTIONAL: Responsive adjustments for very small screens */
@media (max-height: 600px) {
  .waitlist-modal {
    padding: 20px;                 /* Reduce padding on very small screens */
  }

  .waitlist-headline {
    font-size: 1.5rem;             /* Slightly smaller headline */
  }

  .waitlist-form h3, .already-joined-form h3 {
    font-size: 1.3rem;             /* Smaller form heading */
  }

  .submit-btn, .close-btn {
    padding: 10px;                 /* Slightly smaller buttons */
  }
}
/* Container for the badges */
/* Container for the badges */
/* Container for the badges */
.app-badges {
  margin-top: 20px;          /* Keeps some space above the badges */
  display: flex;             /* Use flexbox for horizontal alignment */
  justify-content: center;   /* Center the badges horizontally */
  align-items: center;       /* Vertically align the badges if needed */
}

/* Each badge container enforces a uniform size */
.badge-container {
  position: relative;        /* Allows the "Coming Soon" label to be absolutely positioned */
  display: inline-flex;      /* Flex to center the image inside the container */
  justify-content: center;   
  align-items: center;
  margin: 0 10px;            /* Even spacing between badges */
  width: 150px;              /* Fixed width for uniform sizing */
  height: 50px;              /* Fixed height for uniform sizing */
  overflow: hidden;          /* Hide any overflow if aspect ratios differ */
}

/* Ensure both images fit inside the container without distortion */
.badge-container img.store-badge-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;  /* Keeps the entire image visible within the container */
  display: block;       /* Removes extra space below images */
}

/* "Coming Soon" label positioned at the bottom-right corner */
.badge-container .badge-label {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: #f39c12;   /* Bright orange for visibility */
  color: #fff;           /* White text for contrast */
  font-size: 10px;       
  padding: 2px 5px;      
  border-radius: 5px;    /* Rounded corners for a neat look */
}
.blitz-container {
  position: relative; /* Allows positioning of the trademark info relative to "Blitz" */
  display: inline-block; /* Ensures the container wraps the text correctly */
}

.trademark-info {
  position: absolute; /* Positions the trademark info relative to the "Blitz" text */
  bottom: -0.40em; /* Slightly below the baseline of "Blitz" */
  right: -10em; /* Moves it further to the right */
  font-size: 0.3em; /* Makes the text even smaller */
  color: #cccccc; /* Subtle gray to contrast with red background */
  font-style: italic; /* Optional for a professional appearance */
  line-height: 1; /* Prevents extra vertical spacing */
  white-space: nowrap; /* Ensures the text stays on one line */
  pointer-events: none; /* Ensures it’s not interactive */
}
.waitlist-modal {
  /* existing styles */
  position: relative; /* ensure the skip button can be absolutely positioned inside */
  /* e.g. width, padding, background, etc. */
}
.skip-button {
  position: absolute;
  bottom: 1px;     /* or whatever distance from the bottom you like */
  left: 50%;        /* center horizontally */
  transform: translateX(-50%);
  font-size: 0.9rem;
  color: #666;
  text-decoration: none;
  cursor: pointer;
}

.skip-button:hover {
  color: #333;
}
