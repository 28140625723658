/* Admin dashboard container */
.admin-dashboard {
  padding: 15px;
  background-color: #f4f7f6;
  min-height: 100vh;
  font-size: 14px; /* Smaller font size to fit within screen */
}

/* Dashboard header */
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

/* Logout button */
.logout-button {
  background-color: #d9534f;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.logout-button:hover {
  background-color: #c9302c;
}

/* Admin controls */
.admin-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

/* Search input */
.search-input {
  padding: 8px 10px;
  width: 55%;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 14px;
  margin-bottom: 10px;
}

/* Bulk delete button */
.bulk-delete-button {
  background-color: #d9534f;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.bulk-delete-button:hover {
  background-color: #c9302c;
}

/* Applications table */
.applications-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background-color: white;
  font-size: 13px; /* Reduced font size */
}

.applications-table th, .applications-table td {
  padding: 10px 12px;
  border: 1px solid #ddd;
  text-align: left;
  vertical-align: middle;
}

/* Status styling */
.status.approved {
  color: green;
}

.status.rejected {
  color: red;
}

.status.requested_more_info {
  color: orange;
}

/* Notes textarea */
textarea {
  width: 100%;
  height: 70px; /* Smaller height */
  resize: none; /* No resizing */
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 8px;
  font-size: 13px; /* Smaller font size */
}

/* Action buttons: smaller, circular, vertical alignment */
.approve-button, .reject-button, .info-button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px; /* Adjust font size for icons */
  color: white;
  transition: background-color 0.3s ease;
  margin-bottom: 10px; /* Spacing between buttons */
}

/* Approve button with checkmark icon */
.approve-button::before {
  content: '\2713'; /* Unicode for checkmark */
  font-size: 18px;
  color: white;
}

.approve-button {
  background-color: #5cb85c;
}

.approve-button:hover {
  background-color: #4cae4c;
}

/* Reject button with X icon */
.reject-button::before {
  content: '\2716'; /* Unicode for X mark */
  font-size: 18px;
  color: white;
}

.reject-button {
  background-color: #d9534f;
}

.reject-button:hover {
  background-color: #c9302c;
}

/* Info button with open circle icon */
.info-button::before {
  content: '\25cb'; /* Unicode for open circle */
  font-size: 18px;
  color: white;
}

.info-button {
  background-color: #f0ad4e;
}

.info-button:hover {
  background-color: #ec971f;
}

/* Actions container: vertical stacking */
.actions-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

/* InstaBlitz link button */
.instablitz-link {
  background-color: crimson;
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.instablitz-link:hover {
  background-color: #b21d3a;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .admin-controls {
    flex-direction: column;
    align-items: stretch;
  }

  .search-input {
    width: 100%;
    margin-bottom: 15px;
  }

  .bulk-delete-button {
    width: 100%;
    margin-top: 10px;
  }

  .applications-table th, .applications-table td {
    font-size: 12px;
    padding: 8px 10px;
  }

  .actions-buttons {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .approve-button, .reject-button, .info-button {
    width: 30px;
    height: 30px;
  }
}
