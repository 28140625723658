/* Onboarding.css */

.onboarding-form {
    max-width: 600px;
    margin: 40px auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
    transition: box-shadow 0.3s ease-in-out;
}

.onboarding-form:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.onboarding-form h2 {
    color: #8C1627;
    text-align: center;
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: bold;
}

.form-section {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.onboarding-form label {
    font-size: 15px;
    color: #555;
    margin-bottom: 10px;
    display: block;
    font-weight: 500;
}

.onboarding-form input {
    width: 100%;
    padding: 14px;
    margin-bottom: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 16px;
    background-color: #f9f9f9;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.onboarding-form input:focus {
    border-color: #8C1627;
    background-color: #fff;
}

.phone-verification-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.subtle-btn {
    background-color: #8C1627;
    color: #fff;
    padding: 10px 16px;
    font-size: 14px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.subtle-btn:hover {
    background-color: #a81933;
}

.payment-methods {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
}

.payment-methods label {
    font-size: 14px;
    color: #555;
    cursor: pointer;
}

.payment-methods input[type="radio"] {
    margin-right: 8px;
    cursor: pointer;
}

.scan-license-btn {
    background-color: #8C1627;
    color: #fff;
    padding: 10px 16px;
    font-size: 14px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}

.scan-license-btn:hover {
    background-color: #a81933;
}

.submit-btn {
    width: 100%;
    padding: 14px;
    background-color: #8C1627;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #a81933;
}

/* Terms and Conditions Section */
.terms-link {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 14px;
    justify-content: center;
}

.terms-link label {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
    color: #555;
    cursor: pointer;
}

.terms-link input[type="checkbox"] {
    margin-right: 10px;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.terms-link a {
    color: #8C1627;
    text-decoration: underline;
    cursor: pointer;
}

.terms-link a:hover {
    color: #a81933;
    text-decoration: none;
}

/* Loading Bar */
.loading-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.loading-bar .progress {
    width: 100%;
    height: 6px;
    background-color: #e0e0e0;
    overflow: hidden;
    position: relative;
    border-radius: 3px;
    margin-right: 10px;
}

.loading-bar .progress::before {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: #8C1627;
    animation: loading 1s infinite;
}

@keyframes loading {
    0% {
        left: -50%;
    }
    50% {
        left: 100%;
    }
    100% {
        left: -50%;
    }
}

/* reCAPTCHA Instruction */
.recaptcha-instruction {
    font-size: 13px;
    color: #555;
    margin-top: 10px;
    text-align: center;
}

/* Adjust reCAPTCHA container */
#recaptcha-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

/* Responsive Design */
@media (max-width: 600px) {
    .onboarding-form {
        padding: 20px;
    }

    .onboarding-form h2 {
        font-size: 22px;
    }

    .phone-verification-container {
        flex-direction: column;
        gap: 10px;
    }

    .payment-methods {
        flex-direction: column;
        gap: 10px;
    }

    .terms-link {
        flex-direction: row;
        align-items: center;
        font-size: 13px;
    }

    .terms-link input[type="checkbox"] {
        margin-right: 10px;
        margin-bottom: 0;
    }
}
