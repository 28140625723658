@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* General Styling */
.one-pager {
  font-family: 'Arial', sans-serif;
  color: #333;
  background-color: #f7f7f7;
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensure all elements use border-box sizing */
}

/* Hero Section */
.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 90vh;
  background-color: #8C1627;
  text-align: left;
  padding: 20px;
  color: white;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
}

.logo {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 150px;
}

.main-nav {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  grid-gap: 15px;
  gap: 15px;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s;
}

.nav-link:hover {
  border-bottom: 2px solid white;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 5px 0;
  transition: 0.4s;
}

/* Navigation Menu */
.main-nav {
  display: flex;
}

.main-nav.active {
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  position: absolute;
  top: 70px;
  right: 20px;
  background-color: #8C1627;
  padding: 15px;
  border-radius: 8px;
}

.main-nav.active .nav-link {
  padding: 10px 0;
}

/* Content */
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap; /* Allows wrapping for smaller screens */
}

.text-content {
  max-width: 45%;
  padding-right: 20px;
  margin-bottom: 20px; /* Space between text and image on smaller screens */
}

.text-content h1 {
  font-size: 3.5rem;
  margin-bottom: 20px;
  color: white;
}

.text-content p {
  font-size: 1.4rem;
  margin-bottom: 30px;
}

.main-image {
  max-width: 30%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  margin-left: 20px;
}

.cta-buttons {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap; /* Allows buttons to wrap on smaller screens */
}

.cta-buttons .btn {
  background-color: white;
  color: #8C1627;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 25px;
  border: 2px solid #8C1627;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap; /* Prevent button text from wrapping */
  box-sizing: border-box; /* Ensure button size accounts for padding and borders */
  max-width: 100%; /* Ensure button does not overflow on small screens */
}

.cta-buttons .btn:hover {
  background-color: #8C1627;
  color: white;
}

/* About Section */
.about-section {
  padding: 60px 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  margin: 40px 0;
  text-align: center;
}

.about-content {
  max-width: 80%;
  margin: 0 auto;
}

.about-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #8C1627;
}

.about-content p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #555;
}

.highlight {
  color: #8C1627;
  font-weight: bold;
}

/* Earnings Comparison Section */
.earnings-comparison-section {
  padding: 60px 20px;
  background: linear-gradient(135deg, #f7f7f7, #e2e2e2);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  margin: 40px 0;
  text-align: center;
}

.comparison-content {
  max-width: 80%;
  margin: 0 auto;
}

.comparison-inputs {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  margin: 20px 0;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-group label {
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #555;
}

.input-group input {
  width: 120px;
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  text-align: center;
}

.calculate-btn {
  background-color: #8C1627;
  color: white;
  padding: 15px 30px;
  border-radius: 25px;
  border: none;
  font-weight: bold;
  font-size: 1.2rem;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s, transform 0.3s;
}

.calculate-btn:hover {
  background-color: #6b1120;
  transform: translateY(-2px);
}

.comparison-results {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  grid-gap: 20px;
  gap: 20px;
}

.result-group {
  flex: 1 1;
  padding: 20px;
  background: linear-gradient(135deg, #ffffff, #f9f9f9);
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.result-group.highlight {
  background: linear-gradient(135deg, #8C1627, #a83232);
  color: white;
}

.result-group.highlight .result-box p {
  color: white; /* Ensures all text inside the Blitz box is white */
}

.result-group.highlight .result-box span {
  color: white; /* Ensures the highlighted text inside the Blitz box is white */
}

.result-group:hover {
  transform: translateY(-10px);
}

.result-box {
  border-radius: 10px;
  padding: 15px;
  background-color: transparent;
  margin-top: 10px;
}

.result-box p {
  font-size: 1.5rem;
  color: inherit;
}

.result-box span {
  font-weight: bold;
  color: #333;
}

.progress-bar {
  width: 100%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  margin-top: 10px;
}

.progress {
  height: 100%;
  background-color: #8C1627;
  border-radius: 5px;
  transition: width 0.3s;
}

/* How It Works Section */
.how-it-works-section {
  padding: 60px 20px;
  text-align: center;
  background-color: #f1f1f1;
  margin: 40px 0;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.how-it-works-section h2 {
  font-size: 2.5rem;
  color: #8C1627;
  margin-bottom: 40px;
}

.steps {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  flex-wrap: wrap;
}

.step {
  width: 30%;
  min-width: 250px;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.step:hover {
  transform: translateY(-10px);
}

.step h3 {
  font-size: 1.8rem;
  color: #8C1627;
  margin-bottom: 10px;
}

.step p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

/* Drive Section */
.cta-driver-section {
  padding: 60px 20px;
  text-align: center;
  background-color: #ffffff;
  margin: 40px 0;
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.driver-content {
  max-width: 70%;
  margin: 0 auto;
}

.benefits-list {
  text-align: left;
  margin: 20px 0 40px;
  list-style: none;
  padding-left: 0;
}

.benefits-list li {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #555;
}

.checkmark {
  color: #8C1627;
  font-weight: bold;
  margin-right: 10px;
}

.primary-btn {
  display: inline-block;
  padding: 15px 30px;
  background-color: #8C1627;
  color: white;
  font-weight: bold;
  border-radius: 25px;
  border: 2px solid #8C1627;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap; /* Prevent button text from wrapping */
  max-width: 100%; /* Ensure button does not overflow on small screens */
  box-sizing: border-box; /* Ensure button size accounts for padding and borders */
}

.primary-btn:hover {
  background-color: white;
  color: #8C1627;
}

/* Footer */
footer {
  background-color: #8C1627;
  color: white;
  padding: 20px;
  text-align: center;
}

.footer-links a {
  margin: 0 15px;
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.footer-links a:hover {
  text-decoration: underline;
}

/* Responsive Design for Mobile & Tablet */
@media (max-width: 1024px) {
  .text-content {
    max-width: 100%;
    padding-right: 0;
  }

  .main-image {
    margin-left: 0;
    max-width: 100%;
    margin-top: 20px; /* Adds space between text and image on smaller screens */
  }

  .about-content, .comparison-content, .driver-content {
    max-width: 90%;
  }

  .cta-buttons {
    justify-content: center; /* Center buttons on smaller screens */
  }

  .comparison-results {
    flex-direction: column;
    align-items: center; /* Center the result boxes on smaller screens */
  }

  .result-group {
    width: 100%;
    margin-bottom: 20px; /* Adds spacing between result groups on smaller screens */
  }

  .step {
    width: 100%; /* Full width on smaller screens */
  }

  .primary-btn {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    height: auto; /* Adjusts height for smaller screens */
    text-align: center; /* Centers text on smaller screens */
    padding: 40px 20px;
  }

  .logo {
    position: static;
    margin-bottom: 20px;
  }

  .main-nav {
    display: none;
  }

  .main-nav.active {
    display: flex;
  }

  .hamburger {
    display: flex;
  }

  .text-content {
    text-align: center; /* Centers text on smaller screens */
    padding-right: 0;
  }

  .text-content h1 {
    font-size: 2.5rem; /* Adjusted for smaller screens */
  }

  .text-content p {
    font-size: 1.2rem; /* Adjusted for smaller screens */
  }

  .about-section, .how-it-works-section, .earnings-comparison-section, .cta-driver-section {
    padding: 30px 20px;
  }

  .comparison-inputs {
    flex-direction: column;
    align-items: center; /* Center the inputs on smaller screens */
  }

  .input-group input {
    width: 100%; /* Full width on smaller screens */
  }

  .calculate-btn {
    width: 100%;
  }

  .comparison-results {
    flex-direction: column;
    align-items: center; /* Center the result boxes on smaller screens */
  }

  .result-group {
    width: 100%;
    margin-bottom: 20px; /* Adds spacing between result groups on smaller screens */
  }

  .step {
    width: 100%; /* Full width on smaller screens */
  }

  .primary-btn {
    width: 100%;
  }
}

/* Additional Responsive Adjustments */
@media (max-width: 480px) {
  .hero-section {
    padding: 20px 10px;
  }

  .text-content h1 {
    font-size: 2rem; /* Further adjustment for very small screens */
  }

  .text-content p {
    font-size: 1rem;
  }

  .step h3 {
    font-size: 1.5rem; /* Adjusted for smaller screens */
  }

  .step p {
    font-size: 0.9rem; /* Adjusted for smaller screens */
  }

  .about-content h2,
  .how-it-works-section h2 {
    font-size: 2rem; /* Smaller font size for smaller screens */
  }
}
/* InstaBlitz Section Styling */
.instablitz-section {
  padding: 50px;
  background-color: #f5f5f5;
  text-align: center;
}

.instablitz-steps {
  display: flex;
  justify-content: center;
  align-items: stretch; /* Ensure steps stretch to the same height */
  grid-gap: 20px;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap; /* Allows steps to wrap on smaller screens */
}

.step {
  flex: 1 1 250px; /* Ensures each step has equal width and grows uniformly */
  margin: 10px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center-aligns content inside each step */
}

.step:hover {
  transform: translateY(-5px); /* Adds a hover effect */
}

.step .icon-wrapper {
  width: 60px;
  height: 60px;
  margin-bottom: 15px; /* Space between the icon and the heading */
  display: flex;
  justify-content: center;
  align-items: center;
}

.step .icon {
  font-size: 50px;
  color: #8c1627;
}

.step h3 {
  font-size: 1.5rem;
  margin: 10px 0;
}

.step .content {
  flex-grow: 1; /* Makes the content area take up remaining space */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.step p {
  font-size: 1rem;
  line-height: 1.6;
  margin: 0;
  flex-grow: 1;
}

.instablitz-cta {
  margin-top: 40px;
}

.cta-btn {
  background-color: #8c1627;
  color: white;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
}

/* Mobile Adjustments for InstaBlitz Section */
@media (max-width: 768px) {
  .instablitz-steps {
    flex-direction: column; /* Stack steps vertically on mobile */
    align-items: center;
  }

  .step {
    width: 90%; /* Full width on smaller screens */
    margin-bottom: 20px;
  }

  .step .icon-wrapper {
    width: 50px;
    height: 50px;
  }

  .step .icon {
    font-size: 40px; /* Adjust icon size for smaller screens */
  }

  .step h3 {
    font-size: 1.3rem; /* Adjust heading size on mobile */
  }

  .step p {
    font-size: 0.9rem; /* Adjust text size for mobile */
  }
}
.countdown-banner {
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2); /* Soft, neutral gradient */
  color: #333; /* Dark text for contrast */
  padding: 15px; /* Reduced padding for smaller screens */
  text-align: center;
  font-size: 1.3rem; /* Slightly smaller default font size */
  font-weight: 600; /* Maintain emphasis */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slightly deeper shadow for elegance */
  border-radius: 8px; /* Rounded corners for a softer look */
  margin: 15px auto; /* Adjusted margin for mobile */
  max-width: 90%; /* Narrower on mobile for better spacing */
  position: relative; /* Ensure structure stays intact */
  z-index: 10; /* Keep banner above other elements */
  transition: transform 0.3s ease-in-out;
}

.countdown-banner:hover {
  transform: translateY(-5px); /* Hover effect */
}

.countdown-banner span {
  color: #ff4b5c; /* Vibrant red for countdown */
  font-weight: 600;
  margin-left: 8px; /* Adjusted for mobile spacing */
  font-size: 1.4rem; /* Smaller countdown font on mobile */
}

.countdown-banner p {
  margin: 0;
  line-height: 1.4; /* Slightly tighter line height on mobile */
}

.countdown-banner p::before {
  content: '🚀 ';
  font-size: 1.5rem; /* Slightly smaller rocket icon for mobile */
  vertical-align: middle;
}

/* Larger Screens */
@media (min-width: 768px) {
  .countdown-banner {
    font-size: 1.6rem;
    padding: 25px;
    max-width: 85%; /* Slightly wider for larger screens */
  }

  .countdown-banner span {
    font-size: 1.7rem; /* Larger countdown on bigger screens */
    margin-left: 10px;
  }

  .countdown-banner p::before {
    font-size: 1.8rem; /* Larger icon on bigger screens */
  }
}

/* Small Mobile Screens (e.g., 320px wide) */
@media (max-width: 400px) {
  .countdown-banner {
    padding: 12px; /* Tighter padding on very small screens */
    font-size: 1.2rem; /* Further reduced font size */
  }

  .countdown-banner span {
    font-size: 1.3rem; /* Smaller countdown text */
  }

  .countdown-banner p::before {
    font-size: 1.3rem; /* Smaller rocket icon */
  }
}

/* General Button Style */
.btn {
  background-color: #8C1627; /* New main button color */
  color: #fff; /* White text for contrast */
  padding: 12px 25px; /* Balanced padding */
  border: none; /* No border */
  border-radius: 30px; /* Smooth rounded edges */
  text-transform: uppercase; /* Uppercase for emphasis */
  font-weight: 600; /* Bold font for prominence */
  font-size: 1.1rem; /* Slightly larger font for readability */
  text-align: center;
  text-decoration: none; /* Remove underline */
  display: inline-block;
  cursor: pointer; /* Pointer cursor */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
}

.btn:hover {
  background-color: #721221; /* Darker shade of the button color on hover */
  transform: translateY(-2px); /* Slight hover animation */
}

.btn:active {
  transform: translateY(0); /* Reset transform on click */
}

.btn:focus {
  outline: none; /* No outline on focus */
  box-shadow: 0 0 0 4px rgba(140, 22, 39, 0.4); /* Subtle focus ring for accessibility */
}

/* CTA Button in How It Works Section */
.cta-btn {
  background-color: #8C1627; /* Consistent main button color */
}

.cta-btn:hover {
  background-color: #721221; /* Darken the button color on hover */
}

.cta-btn:active {
  transform: translateY(0); /* No transform on click */
}

.cta-btn:focus {
  outline: none; /* Remove focus outline */
  box-shadow: 0 0 0 4px rgba(140, 22, 39, 0.4); /* Consistent focus ring */
}
/* Waitlist Modal Overlay */
.waitlist-overlay {
  position: fixed;                 /* Keep the overlay fixed */
  top: 0;                          /* Align at top */
  left: 0;                         /* Align at left */
  width: 100%;                     /* Full width */
  height: 100%;                    /* Full height */
  background: rgba(0,0,0,0.6);     /* Semi-transparent black overlay */
  display: flex;                   /* Use flex for centering */
  justify-content: center;         /* Center horizontally */
  align-items: center;             /* Center vertically */
  z-index: 9999;                   /* High z-index to overlay everything */
}

/* Waitlist Modal Container */
.waitlist-modal {
  background: #fff;                /* White background */
  border-radius: 12px;             /* Rounded corners */
  max-width: 400px;                /* Max width of the modal */
  width: 90%;                      /* Responsive width */
  padding: 30px;                   /* Inner padding */
  box-shadow: 0 5px 30px rgba(0,0,0,0.2); /* Drop shadow */
  text-align: center;              /* Center text */
  position: relative;              /* Position relative for internal elements */
  max-height: 90%;                 /* Limit max height on small screens */
  overflow-y: auto;                /* Enable vertical scrolling if content overflows */
}

/* Blitz Logo at the top of the waitlist modal */
.waitlist-logo {
  width: 150px;                    /* Logo width */
  margin: 0 auto 20px;             /* Center and space below */
  display: block;                  /* Block for easy centering */
}

/* Headline and Subtext above the selection buttons/form */
.waitlist-headline {
  font-size: 1.8rem;               /* Larger headline text */
  color: #8C1627;                  /* Brand color */
  font-weight: bold;               /* Bold text */
  margin-bottom: 10px;             /* Spacing below */
}

.waitlist-subtext {
  font-size: 1rem;                 /* Normal font size */
  color: #555;                     /* Dark gray text */
  margin-bottom: 30px;             /* Spacing below */
}

/* Waitlist Selection */
.waitlist-selection h3 {
  font-size: 1.5rem;               /* Heading font size */
  color: #333;                     /* Dark text */
  margin-bottom: 15px;             /* Spacing below heading */
}

.waitlist-selection p {
  font-size: 1rem;                 /* Normal text size */
  color: #555;                     /* Dark gray text */
  margin-bottom: 20px;             /* Spacing below paragraph */
}

.selection-buttons {
  display: flex;                   /* Flex for side-by-side buttons */
  justify-content: space-between;  /* Space between them */
  grid-gap: 15px;
  gap: 15px;                       /* Gap between buttons */
  margin-bottom: 20px;             /* Spacing below buttons */
}

.selection-buttons .btn {
  flex: 1 1;                         /* Each button takes equal width */
  padding: 15px;                   /* Button padding */
  font-size: 1rem;                 /* Font size */
  background: #8C1627;             /* Brand background */
  color: #fff;                     /* White text */
  border-radius: 8px;              /* Rounded corners */
  font-weight: bold;               /* Bold font */
  border: none;                    /* No border */
  cursor: pointer;                 /* Pointer on hover */
  transition: background 0.3s;     /* Smooth hover transition */
}
.selection-buttons .btn:hover {
  background: #721221;             /* Darker background on hover */
}

/* NEW: Already Joined Link */
.already-joined-link {
  font-size: 0.9rem;               /* Slightly smaller text */
  color: #8C1627;                  /* Brand color */
  text-decoration: underline;      /* Underline to indicate link */
  cursor: pointer;                 /* Pointer on hover */
  margin-top: 10px;                /* Spacing above link */
}

/* Waitlist Form */
.waitlist-form h3 {
  font-size: 1.5rem;               /* Heading font size */
  color: #333;                     /* Dark text */
  margin-bottom: 20px;             /* Spacing below heading */
}

.waitlist-form .form-group {
  text-align: left;                /* Left-align labels & inputs */
  margin-bottom: 15px;             /* Spacing between fields */
}

.waitlist-form .form-group label {
  font-size: 0.9rem;               /* Label font size */
  color: #333;                     /* Dark text */
  margin-bottom: 5px;              /* Space below label */
  display: block;                  /* Block to place input below */
}

.waitlist-form .form-group input {
  width: 100%;                     /* Full width input */
  padding: 10px;                   /* Padding inside input */
  font-size: 1rem;                 /* Input font size */
  border: 1px solid #ccc;          /* Light gray border */
  border-radius: 6px;              /* Rounded corners */
  transition: border-color 0.3s;   /* Smooth focus transition */
}

.waitlist-form .form-group input:focus {
  border-color: #8C1627;           /* Brand color on focus */
  outline: none;                   /* Remove outline */
}

.submit-btn {
  width: 100%;                     /* Full width submit button */
  padding: 12px;                   /* Padding inside button */
  font-size: 1rem;                 /* Font size */
  border: none;                    /* No border */
  border-radius: 8px;              /* Rounded corners */
  background: #8C1627;             /* Brand background */
  color: #fff;                     /* White text */
  font-weight: bold;               /* Bold font */
  text-transform: uppercase;       /* Uppercase text */
  margin-top: 20px;                /* Spacing above button */
  cursor: pointer;                 /* Pointer on hover */
  transition: background 0.3s;     /* Smooth hover transition */
}
.submit-btn:hover {
  background: #721221;             /* Darker background on hover */
}

/* Thank You Message */
.thank-you-message {
  text-align: center;              /* Center text */
}

.thank-you-message .check-icon {
  font-size: 3rem;                 /* Large icon */
  color: green;                    /* Green check icon */
  margin-bottom: 20px;             /* Spacing below icon */
}

.thank-you-message h3 {
  font-size: 1.5rem;               /* Heading size */
  color: #333;                     /* Dark text */
  margin-bottom: 10px;             /* Spacing below heading */
}

.thank-you-message p {
  font-size: 1rem;                 /* Normal text */
  color: #555;                     /* Dark gray text */
  margin-bottom: 30px;             /* Spacing below paragraph */
}

.close-btn {
  background: #8C1627;             /* Brand background */
  color: #fff;                     /* White text */
  border: none;                    /* No border */
  font-size: 1rem;                 /* Font size */
  padding: 10px 20px;              /* Padding inside button */
  border-radius: 8px;              /* Rounded corners */
  cursor: pointer;                 /* Pointer on hover */
  font-weight: bold;               /* Bold font */
  transition: background 0.3s;     /* Smooth hover transition */
}
.close-btn:hover {
  background: #721221;             /* Darker background on hover */
}

/* NEW: Already Joined Form */
.already-joined-form h3 {
  font-size: 1.5rem;               /* Heading size */
  color: #333;                     /* Dark text */
  margin-bottom: 20px;             /* Spacing below heading */
}

.already-joined-form .form-group {
  text-align: left;                /* Left-align labels & inputs */
  margin-bottom: 15px;             /* Spacing between fields */
}

.already-joined-form .form-group label {
  font-size: 0.9rem;               /* Label font size */
  color: #333;                     /* Dark text */
  margin-bottom: 5px;              /* Space below label */
  display: block;                  /* Block to place input below */
}

.already-joined-form .form-group input {
  width: 100%;                     /* Full width input */
  padding: 10px;                   /* Padding inside input */
  font-size: 1rem;                 /* Input font size */
  border: 1px solid #ccc;          /* Light gray border */
  border-radius: 6px;              /* Rounded corners */
  transition: border-color 0.3s;   /* Smooth focus transition */
}

.already-joined-form .form-group input:focus {
  border-color: #8C1627;           /* Brand color on focus */
  outline: none;                   /* Remove outline */
}

.already-joined-error {
  color: red;                      /* Red text for error */
  font-size: 0.9rem;               /* Small error text */
  margin-bottom: 10px;             /* Spacing below error message */
}

.return-to-selection {
  font-size: 0.9rem;               /* Slightly smaller text */
  color: #8C1627;                  /* Brand color */
  text-decoration: underline;      /* Underline link */
  cursor: pointer;                 /* Pointer on hover */
  margin-top: 15px;                /* Spacing above link */
}

/* OPTIONAL: Responsive adjustments for very small screens */
@media (max-height: 600px) {
  .waitlist-modal {
    padding: 20px;                 /* Reduce padding on very small screens */
  }

  .waitlist-headline {
    font-size: 1.5rem;             /* Slightly smaller headline */
  }

  .waitlist-form h3, .already-joined-form h3 {
    font-size: 1.3rem;             /* Smaller form heading */
  }

  .submit-btn, .close-btn {
    padding: 10px;                 /* Slightly smaller buttons */
  }
}
/* Container for the badges */
/* Container for the badges */
/* Container for the badges */
.app-badges {
  margin-top: 20px;          /* Keeps some space above the badges */
  display: flex;             /* Use flexbox for horizontal alignment */
  justify-content: center;   /* Center the badges horizontally */
  align-items: center;       /* Vertically align the badges if needed */
}

/* Each badge container enforces a uniform size */
.badge-container {
  position: relative;        /* Allows the "Coming Soon" label to be absolutely positioned */
  display: inline-flex;      /* Flex to center the image inside the container */
  justify-content: center;   
  align-items: center;
  margin: 0 10px;            /* Even spacing between badges */
  width: 150px;              /* Fixed width for uniform sizing */
  height: 50px;              /* Fixed height for uniform sizing */
  overflow: hidden;          /* Hide any overflow if aspect ratios differ */
}

/* Ensure both images fit inside the container without distortion */
.badge-container img.store-badge-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;  /* Keeps the entire image visible within the container */
  display: block;       /* Removes extra space below images */
}

/* "Coming Soon" label positioned at the bottom-right corner */
.badge-container .badge-label {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background: #f39c12;   /* Bright orange for visibility */
  color: #fff;           /* White text for contrast */
  font-size: 10px;       
  padding: 2px 5px;      
  border-radius: 5px;    /* Rounded corners for a neat look */
}
.blitz-container {
  position: relative; /* Allows positioning of the trademark info relative to "Blitz" */
  display: inline-block; /* Ensures the container wraps the text correctly */
}

.trademark-info {
  position: absolute; /* Positions the trademark info relative to the "Blitz" text */
  bottom: -0.40em; /* Slightly below the baseline of "Blitz" */
  right: -10em; /* Moves it further to the right */
  font-size: 0.3em; /* Makes the text even smaller */
  color: #cccccc; /* Subtle gray to contrast with red background */
  font-style: italic; /* Optional for a professional appearance */
  line-height: 1; /* Prevents extra vertical spacing */
  white-space: nowrap; /* Ensures the text stays on one line */
  pointer-events: none; /* Ensures it’s not interactive */
}
.waitlist-modal {
  /* existing styles */
  position: relative; /* ensure the skip button can be absolutely positioned inside */
  /* e.g. width, padding, background, etc. */
}
.skip-button {
  position: absolute;
  bottom: 1px;     /* or whatever distance from the bottom you like */
  left: 50%;        /* center horizontally */
  transform: translateX(-50%);
  font-size: 0.9rem;
  color: #666;
  text-decoration: none;
  cursor: pointer;
}

.skip-button:hover {
  color: #333;
}

/* Container Styling */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background-color: #FFFFFF;
}

/* Logo Styling */
.login-container .logo {
  width: 150px;
  margin-bottom: 30px;
}

/* Heading Styling */
.login-container h1 {
  font-size: 28px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 30px;
}

/* Email Input Field Styling */
.login-container .input-field {
  width: 100%;
  max-width: 400px;
  height: 50px;
  margin-bottom: 20px;
  padding: 0 20px;
  border-radius: 0; /* Rectangular edges */
  border: 1px solid #cccccc;
  background-color: #f2f2f2;
  font-size: 16px;
  color: #333333;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

/* Password Container to Position Toggle Button */
.login-container .password-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

/* Password Input Field Styling */
.login-container .password-input {
  width: 100%;
  height: 50px;
  padding: 0 40px 0 20px; /* Increased right padding to accommodate toggle button */
  border-radius: 0; /* Rectangular edges */
  border: 1px solid #cccccc;
  background-color: #f2f2f2;
  font-size: 16px;
  color: #333333;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

/* Toggle Password Button Styling */
.login-container .toggle-password-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
}

/* Toggle Password Button Hover Effect */
.login-container .toggle-password-button:hover {
  opacity: 0.7;
}

/* Login Button Styling */
.login-container .login-button {
  width: 100%;
  max-width: 300px; /* Shortened from 400px */
  height: 40px; /* Shortened from 50px */
  border-radius: 0; /* Rectangular edges */
  background-color: #8C1627;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  border: none;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;
}

/* Login Button Hover Effect */
.login-container .login-button:hover:not(.disabled) {
  background-color: #6B0E1C;
}

/* Disabled Login Button Styling */
.login-container .login-button.disabled {
  background-color: #a7a7a7;
  cursor: not-allowed;
}

/* Footer Text Styling */
.login-container .footer-text {
  font-size: 16px;
  color: #666666;
}

/* Register Link Styling */
.login-container .register-link {
  color: #8C1627;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

/* Register Link Hover Effect */
.login-container .register-link:hover {
  color: #6B0E1C;
}

/* Focus Styles for Accessibility */
.login-container .input-field:focus, 
.login-container .password-input:focus {
  border-color: #8C1627;
  box-shadow: 0 0 5px rgba(140, 22, 39, 0.5);
  outline: none;
}

.login-container .toggle-password-button:focus {
  outline: 2px solid #8C1627;
  outline-offset: 2px;
}

.register-container {
  display: flex;                /* Container uses flex for centering */
  flex-direction: column;       /* Stacks elements vertically */
  align-items: center;          /* Center align items horizontally */
  justify-content: center;      /* Center align items vertically */
  height: 100vh;                /* Full viewport height */
  padding: 20px;                /* Padding around the container */
  background-color: #FFFFFF;    /* White background */
}

.logo {
  width: 150px;                 /* Logo width */
  margin-bottom: 30px;          /* Space below the logo */
}

h1 {
  font-size: 28px;              /* Larger text for the heading */
  font-weight: bold;            /* Bold heading */
  color: #333333;               /* Dark gray text color */
  margin-bottom: 30px;          /* Space below heading */
}

.input-field {
  width: 100%;                  /* Full width */
  max-width: 400px;             /* Max width for desktop */
  height: 50px;                 /* Height of the input box */
  margin-bottom: 20px;          /* Space below input */
  padding: 0 20px;              /* Horizontal padding inside input */
  border-radius: 0;             /* REMOVED rounded corners for a square look */
  border: 1px solid #cccccc;    /* Light gray border */
  background-color: #f2f2f2;     /* Light gray background */
  font-size: 16px;              /* Font size inside input */
  color: #333333;               /* Dark gray text */
}

.register-button {
  width: 100%;                  /* Full width */
  max-width: 400px;             /* Max width for desktop */
  height: 50px;                 /* Height of the button */
  border-radius: 0;             /* REMOVED rounded corners for a square look */
  background-color: #8C1627;     /* Brand color background */
  color: #FFFFFF;               /* White text */
  font-weight: bold;            /* Bold text */
  font-size: 18px;              /* Larger font size */
  cursor: pointer;              /* Pointer cursor on hover */
  border: none;                 /* No border */
  margin-bottom: 20px;          /* Space below button */
  transition: background-color 0.3s; /* Smooth hover transition */
}

.register-button.disabled {
  background-color: #a7a7a7;     /* Gray background when disabled */
  cursor: not-allowed;           /* Not-allowed cursor when disabled */
}

.footer-text {
  font-size: 16px;              /* Font size of footer text */
  color: #666666;               /* Gray text color */
}

.login-link {
  color: #8C1627;               /* Brand color link */
  font-weight: bold;            /* Bold link */
  cursor: pointer;              /* Pointer cursor on hover */
}

/* NEW: Password container and toggle button for revealing password */
.password-container {
  position: relative;           /* Position relative to place icon */
  width: 100%;                  /* Full width */
  max-width: 400px;             /* Max width matches input */
  margin-bottom: 20px;          /* Space below container */
}

.toggle-password-button {
  position: absolute;           /* Position absolutely within container */
  right: 20px;                  /* Place near the right edge of input */
  top: 50%;                     /* Vertically center the icon */
  transform: translateY(-50%);  /* Adjust for perfect vertical centering */
  background: none;             /* No background */
  border: none;                 /* No border */
  cursor: pointer;              /* Pointer cursor on hover */
  padding: 0;                   /* No padding */
}

/* Overall container for the onboarding process */
.onboarding-container {
  max-width: 600px;
  margin: 50px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  transition: box-shadow 0.3s ease;
}

.onboarding-container:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

/* Steps styling */
.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  animation: fadeIn 0.6s ease-in-out;
}

.step-container h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: 600;
}

.step-container p {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 25px;
}

/* Input styling */
.step-container input[type="text"],
.step-container input[type="date"],
.step-container input[type="file"] {
  width: 100%;
  padding: 12px 15px;
  margin: 12px 0;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 1rem;
  background-color: #f0f0f0;
  transition: border-color 0.3s;
}

.step-container input[type="text"]:focus,
.step-container input[type="date"]:focus,
.step-container input[type="file"]:focus {
  outline: none;
  border-color: #8C1627;
  background-color: #fff;
}

/* Button container for navigation */
.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

.button-container button {
  background-color: #8C1627;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.button-container button:hover {
  background-color: #75111f;
  transform: translateY(-2px);
}

.button-container button:active {
  transform: scale(0.98);
}

.button-container button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  pointer-events: none;
}

/* Consent checkbox */
.consent-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
}

.consent-button input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.3);
  cursor: pointer;
}

.consent-text {
  font-size: 1rem;
  color: #555;
}

/* Summary section */
.summary {
  text-align: left;
  width: 100%;
  background-color: #f5f5f5;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
}

.summary p {
  font-size: 1.1rem;
  color: #333;
  margin: 8px 0;
}

.summary strong {
  color: #8C1627;
}

/* Submit button */
.submit-button {
  background-color: #8C1627;
  color: #fff;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  text-align: center;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #75111f;
  transform: translateY(-2px);
}

.submit-button:active {
  transform: scale(0.98);
}

/* Keyframe animation for smooth transitions */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Admin dashboard container */
.admin-dashboard {
  padding: 15px;
  background-color: #f4f7f6;
  min-height: 100vh;
  font-size: 14px; /* Smaller font size to fit within screen */
}

/* Dashboard header */
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

/* Logout button */
.logout-button {
  background-color: #d9534f;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.logout-button:hover {
  background-color: #c9302c;
}

/* Admin controls */
.admin-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

/* Search input */
.search-input {
  padding: 8px 10px;
  width: 55%;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 14px;
  margin-bottom: 10px;
}

/* Bulk delete button */
.bulk-delete-button {
  background-color: #d9534f;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.bulk-delete-button:hover {
  background-color: #c9302c;
}

/* Applications table */
.applications-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background-color: white;
  font-size: 13px; /* Reduced font size */
}

.applications-table th, .applications-table td {
  padding: 10px 12px;
  border: 1px solid #ddd;
  text-align: left;
  vertical-align: middle;
}

/* Status styling */
.status.approved {
  color: green;
}

.status.rejected {
  color: red;
}

.status.requested_more_info {
  color: orange;
}

/* Notes textarea */
textarea {
  width: 100%;
  height: 70px; /* Smaller height */
  resize: none; /* No resizing */
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 8px;
  font-size: 13px; /* Smaller font size */
}

/* Action buttons: smaller, circular, vertical alignment */
.approve-button, .reject-button, .info-button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px; /* Adjust font size for icons */
  color: white;
  transition: background-color 0.3s ease;
  margin-bottom: 10px; /* Spacing between buttons */
}

/* Approve button with checkmark icon */
.approve-button::before {
  content: '\2713'; /* Unicode for checkmark */
  font-size: 18px;
  color: white;
}

.approve-button {
  background-color: #5cb85c;
}

.approve-button:hover {
  background-color: #4cae4c;
}

/* Reject button with X icon */
.reject-button::before {
  content: '\2716'; /* Unicode for X mark */
  font-size: 18px;
  color: white;
}

.reject-button {
  background-color: #d9534f;
}

.reject-button:hover {
  background-color: #c9302c;
}

/* Info button with open circle icon */
.info-button::before {
  content: '\25cb'; /* Unicode for open circle */
  font-size: 18px;
  color: white;
}

.info-button {
  background-color: #f0ad4e;
}

.info-button:hover {
  background-color: #ec971f;
}

/* Actions container: vertical stacking */
.actions-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

/* InstaBlitz link button */
.instablitz-link {
  background-color: crimson;
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.instablitz-link:hover {
  background-color: #b21d3a;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .admin-controls {
    flex-direction: column;
    align-items: stretch;
  }

  .search-input {
    width: 100%;
    margin-bottom: 15px;
  }

  .bulk-delete-button {
    width: 100%;
    margin-top: 10px;
  }

  .applications-table th, .applications-table td {
    font-size: 12px;
    padding: 8px 10px;
  }

  .actions-buttons {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .approve-button, .reject-button, .info-button {
    width: 30px;
    height: 30px;
  }
}

/* Onboarding.css */

.onboarding-form {
    max-width: 600px;
    margin: 40px auto;
    padding: 30px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
    transition: box-shadow 0.3s ease-in-out;
}

.onboarding-form:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.onboarding-form h2 {
    color: #8C1627;
    text-align: center;
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: bold;
}

.form-section {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.onboarding-form label {
    font-size: 15px;
    color: #555;
    margin-bottom: 10px;
    display: block;
    font-weight: 500;
}

.onboarding-form input {
    width: 100%;
    padding: 14px;
    margin-bottom: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 16px;
    background-color: #f9f9f9;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.onboarding-form input:focus {
    border-color: #8C1627;
    background-color: #fff;
}

.phone-verification-container {
    display: flex;
    justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
}

.subtle-btn {
    background-color: #8C1627;
    color: #fff;
    padding: 10px 16px;
    font-size: 14px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.subtle-btn:hover {
    background-color: #a81933;
}

.payment-methods {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 10px;
}

.payment-methods label {
    font-size: 14px;
    color: #555;
    cursor: pointer;
}

.payment-methods input[type="radio"] {
    margin-right: 8px;
    cursor: pointer;
}

.scan-license-btn {
    background-color: #8C1627;
    color: #fff;
    padding: 10px 16px;
    font-size: 14px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}

.scan-license-btn:hover {
    background-color: #a81933;
}

.submit-btn {
    width: 100%;
    padding: 14px;
    background-color: #8C1627;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #a81933;
}

/* Terms and Conditions Section */
.terms-link {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 14px;
    justify-content: center;
}

.terms-link label {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
    color: #555;
    cursor: pointer;
}

.terms-link input[type="checkbox"] {
    margin-right: 10px;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.terms-link a {
    color: #8C1627;
    text-decoration: underline;
    cursor: pointer;
}

.terms-link a:hover {
    color: #a81933;
    text-decoration: none;
}

/* Loading Bar */
.loading-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.loading-bar .progress {
    width: 100%;
    height: 6px;
    background-color: #e0e0e0;
    overflow: hidden;
    position: relative;
    border-radius: 3px;
    margin-right: 10px;
}

.loading-bar .progress::before {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: #8C1627;
    animation: loading 1s infinite;
}

@keyframes loading {
    0% {
        left: -50%;
    }
    50% {
        left: 100%;
    }
    100% {
        left: -50%;
    }
}

/* reCAPTCHA Instruction */
.recaptcha-instruction {
    font-size: 13px;
    color: #555;
    margin-top: 10px;
    text-align: center;
}

/* Adjust reCAPTCHA container */
#recaptcha-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

/* Responsive Design */
@media (max-width: 600px) {
    .onboarding-form {
        padding: 20px;
    }

    .onboarding-form h2 {
        font-size: 22px;
    }

    .phone-verification-container {
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
    }

    .payment-methods {
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
    }

    .terms-link {
        flex-direction: row;
        align-items: center;
        font-size: 13px;
    }

    .terms-link input[type="checkbox"] {
        margin-right: 10px;
        margin-bottom: 0;
    }
}

.tc-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px; /* Increased padding for more space */
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.8; /* Improved line height for readability */
}

h1 {
    text-align: center;
    margin-bottom: 25px;
    color: #8c1627;
    font-size: 2rem;
}

h2 {
    margin-top: 35px;
    color: #8c1627;
    font-size: 1.5rem;
}

p {
    line-height: 1.8; /* Increased line height for better spacing */
    margin-bottom: 20px; /* Increased margin for separation */
    font-size: 1rem;
}

ul {
    margin-bottom: 25px;
    padding-left: 25px; /* Increased padding for better spacing */
}

li {
    margin-bottom: 15px; /* Increased spacing between list items */
    font-size: 1rem;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
    .tc-container {
        padding: 20px; /* Adjusted padding for tablets */
    }

    h1 {
        font-size: 1.75rem;
    }

    h2 {
        font-size: 1.3rem;
        margin-top: 25px;
    }

    p, li {
        font-size: 0.95rem;
        line-height: 1.6; /* Slightly increased for readability */
    }

    ul {
        padding-left: 20px;
    }
}

/* Extra small devices (max-width: 480px) */
@media (max-width: 480px) {
    .tc-container {
        padding: 15px; /* Reduced padding for small screens */
    }

    h1 {
        font-size: 1.5rem;
        margin-bottom: 20px;
    }

    h2 {
        font-size: 1.2rem;
        margin-top: 20px;
    }

    p, li {
        font-size: 0.9rem;
        line-height: 1.5; /* Adjusted for small screens */
    }

    ul {
        padding-left: 15px;
    }
}

/* Styles scoped specifically for the ToS container */
.tos-container {
  padding: 40px 20px;
  max-width: 900px;
  margin: 50px auto;
  background: #ffffff; /* Changed to solid white for better readability */
  border: 1px solid #dcdcdc; /* Added a subtle border for structure */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-family: 'Roboto', sans-serif;
  color: #2c2c2c; /* Neutral dark gray for text */
  line-height: 1.8;
  transition: all 0.3s ease;
}

.tos-container:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* Header styling */
.tos-container h1 {
  font-size: 34px;
  color: #1a1a1a; /* Black for bold emphasis */
  text-align: center;
  margin-bottom: 25px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
  border-bottom: 2px solid #dcdcdc; /* Subtle underline effect */
  padding-bottom: 10px;
}

.tos-container h2 {
  font-size: 26px;
  color: #2c2c2c; /* Dark gray for headings */
  margin-top: 30px;
  margin-bottom: 15px;
  text-transform: capitalize;
  font-weight: 600;
}

/* Paragraph styling */
.tos-container p {
  font-size: 17px;
  line-height: 1.8;
  color: #333333; /* Neutral dark gray for body text */
  margin-bottom: 15px;
  text-align: justify;
}

/* Uniform bullet point styling */
.tos-container ul {
  margin: 15px 0 20px 40px;
  padding-left: 0;
  list-style-type: disc;
}

.tos-container ul li {
  margin-bottom: 10px;
  font-size: 17px;
  color: #2c2c2c;
}

/* Link styling */
.tos-container a {
  color: #007acc; /* Softer blue for links */
  text-decoration: none;
  transition: color 0.2s ease;
}

.tos-container a:hover {
  color: #005999; /* Darker blue on hover */
  text-decoration: underline;
}

/* Strong text */
.tos-container strong {
  font-weight: 700;
  color: #000000; /* Black for emphasis */
}

/* Blockquote styling */
.tos-container blockquote {
  margin: 20px 0;
  padding: 20px;
  background: #f8f8f8; /* Light gray for contrast */
  border-left: 5px solid #007acc;
  font-style: italic;
  color: #4d4d4d;
}

/* Footer styling */
.tos-container footer {
  text-align: center;
  margin-top: 40px;
}

.tos-container footer a {
  color: #333333; /* Neutral gray for footer links */
  font-size: 16px;
  margin: 0 15px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.5px;
}

/* Responsive styling */
@media (max-width: 768px) {
  .tos-container {
      padding: 20px 15px;
  }

  .tos-container h1 {
      font-size: 28px;
  }

  .tos-container h2 {
      font-size: 22px;
  }

  .tos-container p, .tos-container ul li {
      font-size: 15px;
  }

  .tos-container footer a {
      font-size: 14px;
  }
}

/* InstaBlitzDashboard.css */

/* Import Google Fonts */

/* Root Styles */
.instablitz-dashboard {
  padding: 30px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

/* Dashboard Header */
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dashboard-header h2 {
  font-size: 32px;
  margin: 0;
  color: #2D3748;
  font-weight: 700;
}

.header-buttons {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
}

.logout-btn,
.back-to-admin-btn {
  background-color: #3182CE;
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.logout-btn:hover,
.back-to-admin-btn:hover {
  background-color: #2B6CB0;
  transform: translateY(-2px);
}

.logout-btn:active,
.back-to-admin-btn:active {
  background-color: #2C5282;
  transform: translateY(0);
}

/* Tabs */
.tabs {
  display: flex;
  border-bottom: 2px solid #E2E8F0;
  margin-bottom: 20px;
}

.tabs button {
  background: none;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: #4A5568;
  border-bottom: 2px solid transparent;
  transition: color 0.3s ease, border-bottom-color 0.3s ease;
}

.tabs button:hover {
  color: #2B6CB0;
}

.tabs button.active {
  color: #2B6CB0;
  border-bottom-color: #2B6CB0;
}

/* Payment Counters */
.payment-counters {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 10px;
}

.payment-counters .counter {
  background-color: #EDF2F7;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  color: #2D3748;
}

/* Search Container */
.search-container {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.search-input {
  padding: 12px 20px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #CBD5E0;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.search-input:focus {
  border-color: #3182CE;
  box-shadow: 0 0 0 3px rgba(49, 130, 206, 0.2);
}

/* Loading and Error States */
.loading,
.error,
.no-drivers,
.no-payments {
  text-align: center;
  font-size: 18px;
  color: #555555;
  margin-top: 20px;
}

.error {
  color: #E53E3E;
}

/* Table Container */
.table-container {
  overflow-x: auto;
  margin-top: 20px;
}

/* Table Styling */
table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;
}

table:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

table th,
table td {
  padding: 15px 18px;
  text-align: left;
  border-bottom: 1px solid #ECECEC;
  font-size: 15px;
  color: #4A5568;
}

table th {
  background-color: #F7FAFC;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

table tr:nth-child(even) {
  background-color: #F9FAFB;
}

table tr:hover {
  background-color: #EDF2F7;
  cursor: default;
}

/* Status Badges */
.status-badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}

.status-badge.pending {
  background-color: #ED8936; /* Orange */
}

.status-badge.paid {
  background-color: #38A169; /* Green */
}

.status-badge.rejected {
  background-color: #E53E3E; /* Red */
}

.status-badge.to-review {
  background-color: #3182CE; /* Blue */
}

/* License Image Styling */
.license-img {
  width: 80px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #CBD5E0;
}

/* Action Buttons Styling */
.delete-btn,
.add-payment-btn {
  padding: 8px 14px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  color: #ffffff;
}

.delete-btn {
  background-color: #E53E3E;
}

.delete-btn:hover {
  background-color: #C53030;
  transform: translateY(-2px);
}

.delete-btn:active {
  background-color: #9B2C2C;
  transform: translateY(0);
}

.add-payment-btn {
  background-color: #38A169;
  margin-top: 10px;
}

.add-payment-btn:hover {
  background-color: #2F855A;
  transform: translateY(-2px);
}

.add-payment-btn:active {
  background-color: #276749;
  transform: translateY(0);
}

/* Payment Form Styling */
.payment-form {
  background-color: #F7FAFC;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.payment-form h3 {
  margin-top: 0;
  color: #2D3748;
}

.payment-form .form-group {
  margin-bottom: 15px;
}

.payment-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #4A5568;
}

.payment-form input,
.payment-form select {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #CBD5E0;
  border-radius: 6px;
  font-size: 15px;
  outline: none;
  transition: border-color 0.3s ease;
}

.payment-form input:focus,
.payment-form select:focus {
  border-color: #3182CE;
}

.payment-form button {
  width: 100%;
}

/* Status Actions Styling */
.status-actions {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}

/* Date Picker Container */
.date-picker-container {
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px;
}

.mark-paid-btn {
  padding: 6px 12px;
  background-color: #38A169;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.mark-paid-btn:hover {
  background-color: #2F855A;
  transform: translateY(-2px);
}

.mark-paid-btn:active {
  background-color: #276749;
  transform: translateY(0);
}

/* Responsive Styling */
@media (max-width: 1024px) {
  .instablitz-dashboard {
    padding: 20px;
  }

  .dashboard-header h2 {
    font-size: 28px;
  }

  .search-input {
    max-width: 350px;
  }

  table th,
  table td {
    padding: 12px 16px;
    font-size: 14px;
  }

  .license-img {
    width: 70px;
    height: 50px;
  }

  .delete-btn,
  .add-payment-btn,
  .mark-paid-btn {
    padding: 6px 12px;
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .instablitz-dashboard {
    padding: 15px;
  }

  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 10px;
    gap: 10px;
  }

  .header-buttons {
    width: 100%;
    justify-content: flex-start;
  }

  .search-container {
    justify-content: flex-start;
  }

  .search-input {
    width: 100%;
    max-width: none;
  }

  .license-img {
    width: 60px;
    height: 45px;
  }

  .delete-btn,
  .add-payment-btn,
  .mark-paid-btn {
    width: 100%;
    text-align: center;
  }

  .status-actions {
    flex-direction: column;
  }

  .date-picker-container {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .instablitz-dashboard {
    padding: 10px;
  }

  .dashboard-header h2 {
    font-size: 24px;
  }

  .header-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .logout-btn,
  .back-to-admin-btn {
    width: 100%;
    margin: 5px 0;
  }

  .search-input {
    padding: 10px 14px;
    font-size: 14px;
  }

  table th,
  table td {
    padding: 8px 10px;
    font-size: 12px;
  }

  .license-img {
    width: 50px;
    height: 40px;
  }

  .delete-btn,
  .add-payment-btn,
  .mark-paid-btn {
    padding: 8px 14px;
    font-size: 12px;
  }

  .payment-form {
    padding: 15px;
  }

  .payment-form h3 {
    font-size: 20px;
  }
}

/* src/pages/DriverNavigationPage.css */

/* =========================================
   Root Variables for Theme Colors
   ========================================= */
   :root {
    --primary-color: #8C1627; /* Deep Burgundy */
    --secondary-color: #D9534F; /* Vibrant Red */
    --background-color: #f8f9fa; /* Light Grey */
    --card-background-color: #ffffff; /* White */
    --text-color: #212529; /* Rich Black */
    --accent-text-color: #ffffff; /* White for buttons */
    --border-color: #e0e0e0; /* Subtle Border */
    --shadow-color: rgba(0, 0, 0, 0.1); /* Subtle Shadow */
    --hover-primary: #a91a2c; /* Darker Burgundy */
    --hover-secondary: #c9302c; /* Darker Red */
    --error-color: #ff4d4f; /* Bright Red */
    --success-color: #28a745; /* Green for success messages */
    --font-family: 'Poppins', sans-serif;
  }
  
  /* =========================================
     Global Styles
     ========================================= */
  body {
    margin: 0;
    padding: 0;
    background-color: var(--background-color);
    font-family: var(--font-family);
    color: var(--text-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  * {
    box-sizing: border-box;
  }
  
  /* =========================================
     Blitz Logo
     ========================================= */
  .blitz-logo {
    display: block;
    margin: 0 auto 20px auto;
    width: 150px;
    height: auto;
  }
  
  /* =========================================
     Main Container
     ========================================= */
  .driver-navigation-container {
    width: 95%;
    max-width: 500px;
    margin: 20px auto;
    padding: 25px;
    background-color: var(--card-background-color);
    border-radius: 20px;
    box-shadow: 0 10px 25px var(--shadow-color);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .driver-navigation-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px var(--shadow-color);
  }
  
  .driver-navigation-container h2 {
    font-size: 2rem;
    font-weight: 700;
    color: var(--primary-color);
    text-align: center;
    margin-bottom: 25px;
  }
  
  /* =========================================
     Main Content Styling
     ========================================= */
  .main-content {
    /* Additional styling can be added here if needed */
  }
  
  /* =========================================
     Pickup and Dropoff Section
     ========================================= */
  .pickup-dropoff-container {
    padding: 25px 30px;
    background-color: var(--card-background-color);
    border-radius: 15px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
    margin-bottom: 30px;
  }
  
  .location-row {
    display: flex;
    align-items: center; /* Center vertically to align icons with text */
    grid-gap: 20px;
    gap: 20px; /* Adjusted gap for better alignment */
  }
  
  .location-icon {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 24px; /* Adjusted width */
    min-width: 24px; /* Adjusted min-width */
    height: 60px; /* Fixed height to accommodate icons and line */
  }
  
  /* Create the vertical connecting line using a pseudo-element */
  .location-icon::after {
    content: '';
    position: absolute;
    top: 14px; /* Position below the circle */
    bottom: 14px; /* Position above the rhombus */
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    background-color: var(--primary-color);
    z-index: 1;
  }
  
  .location-icon .circle,
  .location-icon .rhombus {
    width: 12px; /* Smaller size for better alignment */
    height: 12px; /* Smaller size for better alignment */
    background-color: var(--primary-color);
    border: 2px solid var(--card-background-color);
    position: relative;
    z-index: 2;
  }
  
  .location-icon .circle {
    border-radius: 50%;
    margin-bottom: 2px; /* Space between circle and line */
  }
  
  .location-icon .rhombus {
    transform: rotate(45deg);
    margin-top: 2px; /* Space between line and rhombus */
  }
  
  .location-details {
    flex: 1 1;
  }
  
  .location-details p {
    margin: 10px 0;
    font-size: 1.1rem;
    color: var(--text-color);
    line-height: 1.6;
  }
  
  .location-details p span {
    font-weight: 600;
    color: var(--primary-color);
  }
  
  /* =========================================
     Ride Details Section
     ========================================= */
  .ride-details {
    background-color: var(--card-background-color);
    padding: 25px 30px;
    border-radius: 15px;
    box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
    margin-bottom: 30px;
  }
  
  .ride-details p {
    margin: 10px 0;
    font-size: 1.1rem;
    color: var(--text-color);
  }
  
  .ride-details p span {
    font-weight: 600;
    color: var(--primary-color);
  }
  
  /* =========================================
     Buttons
     ========================================= */
  .button-container {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
  }
  
  .navigate-button,
  .complete-ride-button {
    padding: 18px;
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--accent-text-color);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .navigate-button {
    background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  }
  
  .complete-ride-button {
    background: var(--secondary-color);
  }
  
  .navigate-button:hover {
    background: linear-gradient(45deg, var(--hover-primary), var(--hover-secondary));
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .complete-ride-button:hover {
    background: #bf3e3a; /* Darker shade of secondary color */
    transform: translateY(-2px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .navigate-button:disabled,
  .complete-ride-button:disabled {
    background-color: var(--border-color);
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
  
  /* =========================================
     Error and Loading Messages
     ========================================= */
  .error-message,
  .loading-message {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .error-message {
    color: var(--error-color);
  }
  
  .loading-message {
    color: var(--text-color);
  }
  
  /* =========================================
     Responsive Design for Mobile
     ========================================= */
  @media (max-width: 768px) {
    .driver-navigation-container {
      padding: 20px;
    }
  
    .pickup-dropoff-container,
    .ride-details {
      padding: 20px 25px;
      margin-bottom: 25px;
    }
  
    .navigate-button,
    .complete-ride-button {
      padding: 15px;
      font-size: 1rem;
    }
  
    .location-row {
      grid-gap: 15px;
      gap: 15px; /* Reduced gap for smaller screens */
    }
  
    .location-icon {
      width: 20px; /* Adjusted width for smaller screens */
      min-width: 20px; /* Adjusted min-width for smaller screens */
      height: 50px; /* Adjusted height for smaller screens */
    }
  
    .location-icon::after {
      top: 12px; /* Adjusted for smaller icons */
      bottom: 12px; /* Adjusted for smaller icons */
    }
  
    .location-icon .circle,
    .location-icon .rhombus {
      width: 10px; /* Smaller size for mobile */
      height: 10px; /* Smaller size for mobile */
    }
  
    .location-details p {
      font-size: 1rem;
    }
  
    .driver-navigation-container h2 {
      font-size: 1.5rem;
    }
  }
  
.map-container {
  height: 100vh;
  width: 100%;
  position: relative;
}

.completion-container, .summary-container {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.complete-button, .join-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.complete-button:hover, .join-button:hover {
  background-color: #45a049;
}

.summary-card {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  max-width: 600px;
  margin: auto;
  text-align: left;
}

.fare-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.location {
  margin-top: 10px;
  font-size: 16px;
}

.join-prompt {
  margin-top: 30px;
  padding: 10px;
  background-color: #ffeded;
  border-radius: 5px;
}

.coming-soon-container {
    /* Full viewport coverage */
    width: 100vw;
    height: 100vh;
    display: flex; 
    justify-content: center; 
    align-items: center;
  
    /* A subtle background gradient */
    background: linear-gradient(to bottom right, #ffffff, #f4f4f4);
    font-family: sans-serif;
  }
  
  .content-wrapper {
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    background: #fff; /* A white box on the subtle background */
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  }
  
  .coming-soon-logo {
    width: 120px;
    margin-bottom: 20px;
  }
  
  .headline {
    font-size: 2rem;
    color: #8C1627; /* Using your typical red color */
    margin-bottom: 10px;
  }
  
  .subtext {
    font-size: 1.1rem;
    color: #555;
    margin-bottom: 30px;
  }
  
  .home-link {
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;
    color: #8C1627;  /* Corrected this line to use one '#' */
    font-weight: bold;
    border: 2px solid #8C1627;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background 0.3s ease, color 0.3s ease;
  }
  
  .home-link:hover {
    background: #8C1627;
    color: #fff;
  }
  
